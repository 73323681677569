import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Image,
  Text,
  VStack,
  Progress,
  Modal,
  ModalOverlay,
  Textarea,
  IconButton,
  Box,
  Avatar,
} from "@chakra-ui/react";

import { Carousel } from "src/components";
import { LogoPreview } from "src/components/assets";
import { ConversationModalContent, ModalAction } from "src/components/chat";

import {
  ActionTypes,
  ShareSocialMediaPostActionDocument,
  useActionCollection,
  AssetTypes,
  LogoAssetDocument,
  useAssetCollection,
  useBusinessName,
  useIsBrandKitLocked,
  useSelectedDate,
  useBusinessId,
  FirestoreContext,
  ProfileBusinessDocument,
  BusinessContext,
} from "src/db";
import { SocialMediaPostPreview } from "src/components/actions";
import { avatar } from "src/images";
import {
  Path,
  SearchParam,
  useNavigateWithParams,
  useSearchParam,
} from "src/nav";

import { CreateBusinessConversation } from "./createBusiness/CreateBusinessConversation";
import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { RequireBusinessOnly } from "./wrappers/RequireBusiness";
import {
  useActiveThreadId,
  useExternalConversation,
} from "src/components/chat/hooks";
import { useBusinesses, useReportCollection } from "src/db/hooks";
import {
  ReportDocument,
  ReportStatus,
  getShortSummary,
} from "src/db/model/report";
import { Select, useChakraSelectProps } from "chakra-react-select";
import { collection, getDocs, query, where } from "firebase/firestore";

interface AssetExtraData {
  assetName: any; // TODO
  assetLogo: any; // TODO
}

interface BusinessWithAssetName extends ProfileBusinessDocument, AssetExtraData  {}

const CustomLabel = ({ assetName, assetLogo }: AssetExtraData) => (
  <Box display="flex" alignItems="center" gap="4">
    <Avatar name={assetName?.assetData?.assetValue?.name} src={assetLogo?.assetData?.assetValue?.logo?.imageInfo?.imageUrl} boxSize="24px" />
    <Text fontWeight="bold" textStyle="body">
      {assetName?.assetData?.assetValue?.name || "No Brand Name"}
    </Text>
  </Box>
)

export function BrandHubPage() {
  const businessName = useBusinessName();
  const [, setSelectedDate] = useSelectedDate();

  const businesses = useBusinesses();
  const businessId = useBusinessId();

  const { setSelectedBusinessId } = useContext(BusinessContext)

  const [selectedOption, setSelectedOption] = useState<any>();

  const fs = useContext(FirestoreContext);

  const [businessesWithAssetNames, setBusinessesWithAssetNames] = useState<
    BusinessWithAssetName[]
  >([]);

  useEffect(() => {
    // Fetch asset names for each business
    const fetchAssetNames = async () => {
      if (businesses) {
        const updatedBusinesses = await Promise.all(
          businesses.map(async (business) => {
            // Fetch asset names related to this business
            const assetNameQuery = query(
              collection(fs, "assetNames"),
              where("businessId", "==", business.businessId),
              where("selectedForBrandKit", "==", true)
            );

            const assetNameSnapshot = await getDocs(assetNameQuery);
            const assetName = assetNameSnapshot.docs.map((doc) =>
              doc.data()
            )[0];

            // Fetch asset logos related to this business
            const assetLogoQuery = query(
              collection(fs, "assetLogos"),
              where("businessId", "==", business.businessId),
              where("selectedForBrandKit", "==", true)
            );

            const assetLogoSnapshot = await getDocs(assetLogoQuery);
            const assetLogo = assetLogoSnapshot.docs.map((doc) =>
              doc.data()
            )[0];

            // Return business with both asset name and asset logo
            return {
              ...business,
              assetName,
              assetLogo,
            };
          })
        );

        setBusinessesWithAssetNames(updatedBusinesses);
      }
    };

    fetchAssetNames();
  }, [businesses, fs]); // Re-run the effect when businesses or fs change

  // Optionally, you can log the results to check the output
  useEffect(() => {
    if(Boolean(businessesWithAssetNames?.length)) {
      const selectedBrand = businessesWithAssetNames?.find(business => business.businessId === businessId) 
      setSelectedOption({ value: businessId, label: selectedBrand ? <CustomLabel assetLogo={selectedBrand.assetLogo} assetName={selectedBrand.assetName} /> : '' })
    }
  }, [businessId, businessesWithAssetNames]);

  useEffect(() => {
    setSelectedDate(new Date());
  }, [setSelectedDate]);

  const handleBrandChange = ({ value, label }: { value: string; label: string}) => {
    console.log('value', value)
    setSelectedOption({ value, label })
    setSelectedBusinessId(value)
  }

  const selectProps = useChakraSelectProps({
    value: selectedOption,
    onChange: handleBrandChange,
  })

  return (
    <HeaderFooterPage pageTitle="Brand Hub">
      {Boolean(businessesWithAssetNames?.length) && (
        <Box p="4" display="flex" alignItems="center" justifyContent="center">
          <Select
            {...selectProps}
            placeholder="Select brand"
            options={businessesWithAssetNames.map(
              ({ businessId, assetName, assetLogo }) => ({
                label: <CustomLabel assetLogo={assetLogo} assetName={assetName} />,
                value: businessId
              })
            )}
            chakraStyles={{
              container: (provided) => ({
                ...provided,
                width: "250px",
                background: "white",
                cursor: "pointer"
              }),
              inputContainer: (provided) => ({
                ...provided,
              }),
              option: (provided) => ({
                ...provided,
                background: "white",
                gap: "8px",
                borderTop: "1.5px solid F3F4F6",
                borderBottom: "1.5px solid #F3F4F6",
                color: "text.body",
                fontSize: "14px",
                padding: "8px 12px",
              }),
              menuList: (provided) => ({
                ...provided,
                borderRadius: "24px",
                boxShadow:
                  "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 29px 0px rgba(0, 0, 0, 0.10)",
              }),
            }}
          />
        </Box>
      )}
      {businessName && (
        <VStack direction="column" align="center" mx={4}>
          <Text textStyle="body" align="center" maxWidth="240px" mb={6}>
            {businessName}
          </Text>
        </VStack>
      )}
      <RequireBusinessOnly OrElse={<CreateBusiness />}>
        <BrandHubPageContent />
      </RequireBusinessOnly>
    </HeaderFooterPage>
  );
}

function BrandHubPageContent() {
  const isBrandKitLocked = useIsBrandKitLocked();
  const navigate = useNavigateWithParams();
  const logos = useAssetCollection<LogoAssetDocument>(AssetTypes.logo);
  const posts = useActionCollection<ShareSocialMediaPostActionDocument>(
    ActionTypes.shareSocialMediaPostPreview
  );
  const reports = useReportCollection();
  const focusSection = useSearchParam(SearchParam.focusSection);
  const brandActionsSectionRef = useRef<HTMLDivElement>(null);
  const brandPrintsSectionRef = useRef<HTMLDivElement>(null);

  const selectedLogos = logos.filter((logo: LogoAssetDocument) => {
    return logo.selected;
  });
  const suggestedLogos = logos.filter((logo: LogoAssetDocument) => {
    return !logo.selected;
  });
  const previewLogos = !!selectedLogos.length ? selectedLogos : suggestedLogos;

  useEffect(() => {
    if (focusSection === "brandActions") {
      brandActionsSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (focusSection === "brandPrints") {
      brandPrintsSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  });

  // TODO: make this a Link
  const goToBrandkit = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate({ to: Path.brandkit });
    },
    [navigate]
  );

  // TODO: make this a Link
  const goToBrandActions = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate({ to: Path.brandactions });
    },
    [navigate]
  );

  // TODO: make this a Link
  const goToBrandReports = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigate({ to: Path.brandreports });
    },
    [navigate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Card mb={4} p={2}>
        <BrandiSearch />
      </Card>
      <Card mb={12} p={6} onClick={goToBrandkit} order={isBrandKitLocked ? 6 : undefined}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center">
            Brand Kit
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              All the key elements of your brand in one place
            </Text>
            {previewLogos.length === 0 && (
              <Progress isIndeterminate={true} width="50%" my={4} mx="auto" />
            )}
            {!isBrandKitLocked && (
              <Carousel itemWidth={200} itemSpacing={16} mb={2}>
                {previewLogos.map((logo, idx) => {
                  return <LogoPreview key="idx" logo={logo} />;
                })}
              </Carousel>
            )}
          </VStack>
        </CardBody>
        <CardFooter>
          <Button
            colorScheme="primary"
            variant="fill"
            minWidth="200px"
            mx="auto"
            onClick={goToBrandkit}
          >
            Review
          </Button>
        </CardFooter>
      </Card>

      <Card mb={12} p={6} onClick={goToBrandActions}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center" mb={2}>
            Brand Actions
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              Tailored-to-you actions to grow your brand weekly
            </Text>
            {posts.length === 0 && (
              <Progress isIndeterminate={true} width="50%" my={4} mx="auto" />
            )}
            <PostsCarousel posts={posts} />
          </VStack>
        </CardBody>
        <CardFooter></CardFooter>
        <VStack align="center">
          <Button
            colorScheme="primary"
            variant="fill"
            minWidth="200px"
            mx="auto"
            onClick={goToBrandActions}
          >
            See Actions
          </Button>
        </VStack>
      </Card>
      <Card mb={12} p={6} onClick={goToBrandReports}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center" mb={2}>
            Brand Reports
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              Discover your next big thing
            </Text>
            <ReportsCarousel reports={reports} />
          </VStack>
        </CardBody>
        <CardFooter></CardFooter>
        <VStack align="center">
          {!!reports.filter((r) => r.status === ReportStatus.READY).length ? (
            <Button
              colorScheme="primary"
              variant="fill"
              minWidth="200px"
              mx="auto"
              onClick={goToBrandReports}
            >
              See Reports
            </Button>
          ) : (
            reports.length === 0 && (
              <Button
                colorScheme="primary"
                variant="fill"
                minWidth="200px"
                mx="auto"
              >
                Generate a Report
              </Button>
            )
          )}
        </VStack>
      </Card>

      <Card mb={12} p={6}>
        <CardHeader mb={2}>
          <Text as="header" textStyle="titleForSection" align="center" mb={2}>
            Brand Creations
          </Text>
        </CardHeader>
        <CardBody mb={2}>
          <VStack align="center">
            <Text textStyle="body" align="center" mb={2} pl={0.3} pr={0.3}>
              <em>Coming Soon!</em>&nbsp; Boost your brand with Agent Brandi,
              your creative co-pilot to get you from concept to creations and
              campaigns.
            </Text>
          </VStack>
        </CardBody>
      </Card>
    </Box>
  );
}

function BrandiSearch() {
  const businessId = useBusinessId();
  const threadId = useActiveThreadId();
  if (!businessId || !threadId) {
    // Not an error, just means the user is not signed in.
    return <></>;
  } else {
    return <BrandiSearchButton businessId={businessId} threadId={threadId} />;
  }
}
interface ChatButtonProps {
  businessId: string;
  threadId: string;
}

function BrandiSearchButton({ businessId, threadId }: ChatButtonProps) {
  const fabRef = useRef<HTMLButtonElement>(null);

  const conversation = useExternalConversation(businessId, threadId);
  return (
    <>
      <Textarea
        bg="grayscale.light"
        borderRadius="md"
        focusBorderColor="secondary.light"
        placeholder="Ask Agent Brandi..."
        resize="none"
        rows={1}
        onClick={() => conversation.toggleModal(true)}
      />
      <IconButton
        type="submit"
        variant="fill"
        position="absolute"
        bottom="12px"
        right="12px"
        boxSize="32px"
        icon={<Image src={avatar} alt="Talawa" boxSize="64px" />}
        aria-label="Send"
        isRound={true}
        onClick={() => conversation.toggleModal(true)}
      ></IconButton>
      <Modal
        onClose={() => {
          conversation.toggleModal(false);
        }}
        finalFocusRef={fabRef}
        isOpen={conversation.isModalOpen}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ConversationModalContent />
      </Modal>
    </>
  );
}

function CreateBusiness() {
  const getStarted = useRef<HTMLButtonElement>(null);
  const toggleModal = useRef<ModalAction>(null);

  const openConversation = () => {
    !!toggleModal.current && toggleModal.current(true);
  };

  return (
    <VStack align="center">
      <Text textStyle="direction" align="center" mb={2}>
        Tell Us About Your Business
      </Text>
      <CreateBusinessConversation
        finalFocusRef={getStarted}
        toggleModalRef={toggleModal}
        flex="1"
        mx={2}
      />
      <Button
        variant="fill"
        ref={getStarted}
        onClick={openConversation}
        width="100%"
        mb={4}
      >
        Get Started
      </Button>
    </VStack>
  );
}

function PostsCarousel({
  posts,
}: {
  posts: ShareSocialMediaPostActionDocument[];
}) {
  return (
    <Carousel itemWidth={200} itemSpacing={16} mb={2}>
      {posts.map((post, idx) => {
        return (
          <Card key={idx} p={4}>
            <Text as="h4" textStyle="caption" align="left">
              Share
            </Text>
            <SocialMediaPostPreview post={post} />
          </Card>
        );
      })}
    </Carousel>
  );
}

function ReportsCarousel({ reports }: { reports: ReportDocument[] }) {
  return (
    <Carousel itemWidth={200} itemSpacing={16} mb={2} w="100%">
      {reports.map((report, idx) => {
        return (
          <Card
            key={idx}
            p={4}
            minHeight="150px"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Text textStyle="caption" align="left">
                {new Date(report.createdAt).toLocaleDateString()}
              </Text>
              <Text as="header" align="left" flexGrow={1}>
                {getShortSummary(report)}
              </Text>
            </Box>
            <Progress
              isIndeterminate={report.status === ReportStatus.CREATING}
            />
          </Card>
        );
      })}
    </Carousel>
  );
}
