import {
  Avatar,
  Box,
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdCheck, MdKeyboardArrowLeft } from "react-icons/md";
import { RiEdit2Line } from "react-icons/ri";
import { useApi } from "src/api";
import { ROLES } from "./AddMemberModal";

interface EditMemberPermissionsModalProps {
  isOpen: boolean;
  selectedMember: any;
  businessRole?: string;
  handleClose: () => void;
  handleRemoveMember: (profileId: string) => void;
}

const ROLE_OPTIONS = [
  {
    title: "owner",
    description:
      "Can close this team’s account in addition to all admin functions.",
  },
  {
    title: "admin",
    description:
      "Can edit content and manage team account, users and settings.",
  },
  {
    title: "editor",
    description: "Can edit content but cannot update team settings.",
  },
  {
    title: "viewer",
    description: "Can view but not edit team’s content.",
  },
];

const EditMemberPermissionsModal = ({
  isOpen,
  selectedMember,
  businessRole,
  handleClose,
  handleRemoveMember,
}: EditMemberPermissionsModalProps) => {
  const [selectedRole, setSelectedRole] = useState(selectedMember?.role);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();

  const [api, surfaceKnownErrors] = useApi();

  const {
    profile: { profileInfo },
  } = selectedMember;

  const handleEditPermissions = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    api
      .updateMemberPermissions(
        {
          junctionProfileId: selectedMember.id,
          role: selectedRole,
        },
        surfaceKnownErrors
      )
      .then(() => {
        handleClose();
        toast({
          description: "Member permissions updated successfully",
          duration: 3000,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        setSelectedRole(null);
      });
  };

  const enableRemove = businessRole === ROLES.owner;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="full">
      <ModalOverlay />
      <ModalContent
        mt="100px"
        borderRadius="16px 16px 0 0"
        maxH="75%"
        maxWidth={800}
      >
        <ModalHeader>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box position="absolute" left="4">
              <IconButton
                icon={
                  <Icon
                    as={MdKeyboardArrowLeft}
                    color={"gray.medium"}
                    boxSize="32px"
                  />
                }
                aria-label="Menu"
                onClick={handleClose}
                bg={"transparent"}
              />
            </Box>
            <Box>
              <Text color="gray.dark">Edit Permissions</Text>
            </Box>
            {/* <Box position="absolute" right="4">
              <Button bg="primary.dark" borderRadius={1000} w="60px" h="28px" onClick={handleEditPermissions}>
                <Text color="white" textStyle="buttonSmall">Done</Text>
              </Button>
            </Box> */}
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Box>
                <Avatar
                  name={profileInfo?.name || undefined}
                  src={profileInfo?.profileImageInfo?.imageUrl}
                  w={"40px"}
                  h={"40px"}
                />
              </Box>
              <Box px="2">
                <Text color="gray.dark" fontWeight="bold" textStyle="body">
                  {profileInfo?.name}
                </Text>
                <Text color="gray.medium" textStyle="body">
                  {profileInfo?.email || "-"}
                </Text>
              </Box>
            </Box>
            <Box>
              <IconButton
                icon={
                  <Icon
                    as={RiEdit2Line}
                    color={"gray.mediumDark"}
                    boxSize="20px"
                  />
                }
                aria-label="edit member"
                onClick={() => {}}
                w={"40px"}
                h={"40px"}
              />
            </Box>
          </Box>
          <Box my="8">
            {ROLE_OPTIONS.map(({ title, description }, i) => (
              <Box
                display="flex"
                flexDirection="column"
                padding={"16px 12px 16px 0"}
                borderTopWidth={i === 0 ? 0.5 : undefined}
                borderBottomWidth={0.5}
                borderStyle="solid"
                borderColor="gray.light"
                cursor="pointer"
                key={title}
                onClick={() => setSelectedRole(title)}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Box>
                      <Text
                        color="gray.dark"
                        textStyle="body"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {title}
                      </Text>
                    </Box>
                    <Box maxW={300}>
                      <Text color="gray.medium" textStyle="body">
                        {description}
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    {selectedRole === title && (
                      <IconButton
                        icon={
                          <Icon
                            as={MdCheck}
                            color={"gray.mediumDark"}
                            boxSize="20px"
                          />
                        }
                        aria-label="select role"
                        onClick={() => {}}
                        ml="2"
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          <Box mt="2" mb="8">
            <Button
              colorScheme="primary"
              variant="fill"
              width="100%"
              onClick={handleEditPermissions}
              borderRadius="7px"
              isLoading={isSubmitting}
            >
              Update
            </Button>
          </Box>
          {enableRemove && (
            <Box onClick={() => handleRemoveMember(selectedMember.profile.id)}>
              <Text
                color="error"
                textStyle="body"
                fontWeight="bold"
                cursor="pointer"
              >
                Remove
              </Text>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditMemberPermissionsModal;
