import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Firestore, collection, doc, where, query } from 'firebase/firestore';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

import { useUser } from 'src/auth';
import {
  ProfileDocument, profileConverter, useProfileId,
  ProfileBusinessDocument, profileBusinessConverter,
  BusinessDocument, businessConverter,
  UserCreditsDocument, userCreditsConverter,
} from 'src/db'; 
import { SearchParam, useSearchParam, useSearchParamState } from 'src/nav';
import { logger, LogSource } from 'src/util/logger';

import { ActionsProvider } from './ActionsProvider';
import { AssetsProvider } from './AssetsProvider';
import { FirestoreContext, ProfileContext, BusinessContext } from './contexts';
import { backendConfig } from "src/firebaseAndBackendConfig";
import { ReportsProvider } from "./ReportsProvider";
import { MembersProvider } from "./MembersProvider";
import { NotificationsProvider } from "./NotificationsProvider";

declare global {
  interface Window {
    Appcues: any;
  }
}

const missingProfileContext = {
  profileId: null,
  profile: null,
  isLoading: false,
  credits: null,
}

const loadingProfileContext = {
  profileId: null,
  profile: null,
  isLoading: true, // If auth is null, treat profile as still loading.
  credits: null,
}

const missingBusinessContext = {
  businessId: null,
  business: null,
  isLoading: false,
  setSelectedBusinessId: () => {}
}

const loadingBusinessContext = {
  businessId: null,
  business: null,
  isLoading: true,  // If auth is null, treat profile as still loading.
  setSelectedBusinessId: () => {}
}

interface FirestoreProviderProps extends PropsWithChildren{
  fs: Firestore
}
export function FirestoreProvider({fs, children}: PropsWithChildren<FirestoreProviderProps>) {  

  return (
    <FirestoreContext.Provider value={fs}>
      <ProfileAndBusinessProvider>
        <AssetsProvider>
          <ActionsProvider>
            <ReportsProvider>
              <MembersProvider>
                <NotificationsProvider>
                  {children}
                </NotificationsProvider>
              </MembersProvider>
            </ReportsProvider>
          </ActionsProvider>
        </AssetsProvider>
      </ProfileAndBusinessProvider>
    </FirestoreContext.Provider>
  );
}

function ProfileAndBusinessProvider ({children}: PropsWithChildren) {
  const fs = useContext(FirestoreContext);
  const currentUser = useUser(true);
  const userId = currentUser?.uid || '-1';
  const claimBusinessId = useSearchParam(SearchParam.claimBusiness);

  const profilesRef = query<ProfileDocument>(
    collection(fs, 'profiles').withConverter<ProfileDocument>(profileConverter),
    where('userId', '==', userId),
  );
  const [profiles, isLoading, error] = useCollectionData(profilesRef);
  const profile = (!!profiles && profiles[0]) || undefined;

  useEffect(() => {
    logger.updateFirestoreContext({profileId: null});
    if (!!error) { return; }
    if (!!profile) {
      logger.updateFirestoreContext({profileId: profile.id});

      window.Appcues.identify(
        currentUser?.uid,
        {
          email: currentUser?.email,
          name: currentUser?.displayName,
          tier: profile.tier,
          backendUrl: backendConfig.domainUrl,
        },
      );
    }
  }
  , [error, profile, currentUser]);

  if (!!error) {
    logger.error(LogSource.FIRESTORE, 'Error retreiving Profile Provider:', error);
    return (<ProfileContext.Provider value={missingProfileContext}>
      <BusinessContext.Provider value={missingBusinessContext}>
        {children}
      </BusinessContext.Provider>
    </ProfileContext.Provider>);
  }

  if (isLoading) {
    return (<ProfileContext.Provider value={loadingProfileContext}>
      <BusinessContext.Provider value={missingBusinessContext}>
        {children}
      </BusinessContext.Provider>
    </ProfileContext.Provider>);
  }

  if (!profile) {
    if (!!claimBusinessId) {
      return (
        <ProfileContext.Provider value={missingProfileContext}>
          <ClaimBusinessProviderInner businessId={claimBusinessId}>
            {children}
          </ClaimBusinessProviderInner>
        </ProfileContext.Provider>
      );
    } else {
      return (
        <ProfileContext.Provider value={missingProfileContext}>
           <BusinessContext.Provider value={missingBusinessContext}>
            {children}
          </BusinessContext.Provider>
        </ProfileContext.Provider>
      );
    }
  }

  return (
    <ProfileContext.Provider value={{
      profileId: profile!.id, profile: profile, isLoading, credits: null
    }}>
      <ProfileCreditsProvider>
        <BusinessProvider>
          {children}
        </BusinessProvider>
      </ProfileCreditsProvider>
    </ProfileContext.Provider>
  );
}

function ProfileCreditsProvider({ children }: PropsWithChildren) {
  const fs = useContext(FirestoreContext);
  const profileContext = useContext(ProfileContext);
  const profileId = profileContext.profileId!;

  const creditsRef = query<UserCreditsDocument>(
    collection(fs, 'userCredits').withConverter<UserCreditsDocument>(userCreditsConverter),
    where('profileId', '==', profileId),
  );
  const [credits, /* isLoading */, error] = useCollectionData(creditsRef);

  if (!credits || credits.length === 0) {
    if (!!error) {
      logger.error(LogSource.FIRESTORE, 'Error retreiving User Credits:', error);
    }
    return <>{children}</>;
  } else {
    if (credits.length > 1) {
      logger.warn(LogSource.FIRESTORE, 'Duplicate Credits Found:', {
        message: `${[...credits]}`
      });
    }

    return <ProfileContext.Provider value={{...profileContext, credits: credits[0].amount}}>
      {children}
    </ProfileContext.Provider>
  }
}

function BusinessProvider({
  children
}: PropsWithChildren) {
  const fs = useContext(FirestoreContext);
  const profileId = useProfileId();
  const claimBusinessId = useSearchParam(SearchParam.claimBusiness);

  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>()

  const junctionRef = query<ProfileBusinessDocument>(
    collection(fs, 'junctionProfileBusiness').withConverter<ProfileBusinessDocument>(profileBusinessConverter),
    where('profileId', '==', profileId),
  );
  const [junctions, junctionIsLoading, junctionError] = useCollectionData(junctionRef);

  useEffect(() => {
    if(junctions?.length) {
      setSelectedBusinessId(junctions![0]!.businessId)
    }
  }, [junctions])

  // Use businessId from URL param before attempting to read businessId from juntion.
  if (!!claimBusinessId) {
    return (<ClaimBusinessProviderInner businessId={claimBusinessId}>
      {children}
    </ClaimBusinessProviderInner>)
  }

  if (!!junctionError) {
    logger.error(LogSource.FIRESTORE, 'Error retreiving ProfileBusinessJunction:', junctionError);
    return (<BusinessContext.Provider value={missingBusinessContext}>
      {children}
    </BusinessContext.Provider>);
  }

  if (junctionIsLoading) {
    return (<BusinessContext.Provider value={loadingBusinessContext}>
      {children}
    </BusinessContext.Provider>);
  }

  if (!junctions!.length) {
    return (<BusinessContext.Provider value={missingBusinessContext}>
      {children}
    </BusinessContext.Provider>);
  }

  if(selectedBusinessId) {
    return (
      <BusinessProviderInner businessId={selectedBusinessId} businesses={junctions} setSelectedBusinessId={setSelectedBusinessId}>
        {children}
      </BusinessProviderInner>
    )
  }

  return <></>
}

interface BusinessProviderInnerProps {
  businessId: string;
  businesses?: ProfileBusinessDocument[];
  setSelectedBusinessId?: any
}
function BusinessProviderInner ({
  businessId, children, businesses,
  setSelectedBusinessId
}: PropsWithChildren<BusinessProviderInnerProps>) {
  const fs = useContext(FirestoreContext);

  const businessRef = doc(fs, 'businesses', businessId).withConverter<BusinessDocument>(businessConverter);
  const [business, isLoading, error] = useDocumentData<BusinessDocument>(businessRef);

  useEffect(() => {
    if (!!business) {
      logger.updateFirestoreContext({businessId: business.id});
    } else {
      logger.updateFirestoreContext({businessId: null});
    } 
  }, [business]);

  if (!!error) {
    logger.error(LogSource.FIRESTORE, 'Error retreiving Business:', error);
    return (<BusinessContext.Provider value={{...missingBusinessContext, businessId, businesses, setSelectedBusinessId}}>
      {children}
    </BusinessContext.Provider>);
  }

  if (isLoading) {
    return (<BusinessContext.Provider value={{...loadingBusinessContext, businessId, businesses, setSelectedBusinessId}}>
      {children}
    </BusinessContext.Provider>);
  }

  if (!business) {
    logger.error(LogSource.FIRESTORE, `Missing Business for ID: ${businessId}`);
    return (<BusinessContext.Provider value={{...missingBusinessContext, businessId, businesses, setSelectedBusinessId}}>
      {children}
    </BusinessContext.Provider>);
  }

  return (
    <BusinessContext.Provider value={{
      businessId, business, isLoading, businesses, setSelectedBusinessId
    }}>
      {children}
    </BusinessContext.Provider>
  );
}

function ClaimBusinessProviderInner({
  businessId, children,
}: PropsWithChildren<BusinessProviderInnerProps>) {
  const fs = useContext(FirestoreContext);
  const [, setClaimBusinessId] = useSearchParamState(SearchParam.claimBusiness);

  const businessRef = doc(fs, 'businesses', businessId).withConverter<BusinessDocument>(businessConverter);
  const [business, isLoading, error] = useDocumentData<BusinessDocument>(businessRef);

  useEffect(() => {
    if (!!business) {
      logger.updateFirestoreContext({businessId: business.id});
    } else {
      logger.updateFirestoreContext({businessId: null});
    } 
  }, [business]);

  // If any business was already linked to the profile,
  // it will be repopulated in the context after removing the param.
  useEffect(() => {
    if (!!error || isLoading) { return; }
    if (!business) {
      logger.error(LogSource.FIRESTORE, `Missing Business to claim for ID: ${businessId}`);
      setClaimBusinessId(null);
    } else if (business.isClaimed) {
      // Using warn instead of error, because its difficult to tell if this is unintentional,
      // or just the race condition of firestore updating before the param is removed.
      logger.warn(LogSource.FIRESTORE, `Business ${businessId} is already claimed`);
      setClaimBusinessId(null);
    }
  }, [error, isLoading, setClaimBusinessId, businessId, business]);

  if (!!error) {
    logger.error(LogSource.FIRESTORE, 'Error retreiving Business to claim:', error);
    return (<BusinessContext.Provider value={{...missingBusinessContext, businessId}}>
      {children}
    </BusinessContext.Provider>);
  }

  if (isLoading) {
    return (<BusinessContext.Provider value={{...loadingBusinessContext, businessId}}>
      {children}
    </BusinessContext.Provider>);
  }

  if (!business || business.isClaimed) {
    // Will modify search params away in in above effect.
    return <></>;
  }

  return (
    <BusinessContext.Provider value={{
      businessId, business, isLoading,
      setSelectedBusinessId: () => {},
    }}>
      {children}
    </BusinessContext.Provider>
  );
}
