import React, { useCallback, useContext } from 'react';
import { signInAnonymously } from 'firebase/auth';

import {
  Flex,
  Image,
  Spacer,
} from '@chakra-ui/react';

import { AuthContext } from 'src/auth';
import { avatarShadow, talawa } from 'src/images';
import { Path, Redirect, SearchParam, useSearchParam } from 'src/nav';
import { useEffectLate } from 'src/util/async';
import { logger, LogSource } from 'src/util/logger';

import { CreateBusiness } from './createBusiness/CreateBusiness';
import { useCreateBusinessPrefill } from './createBusiness/hooks';
import { AwaitBusiness } from './wrappers/AwaitBusiness';
import { RequireUser } from './wrappers/RequireUser';

function SignInAnonymously() {
  const auth = useContext(AuthContext)!;
  const signInIfNeeded = useCallback(() => {
    if (!!auth.currentUser) { return; }
    logger.info(LogSource.APP, 'Signing in anynymously...');
    signInAnonymously(auth);
  }, [auth]);
  useEffectLate(signInIfNeeded);
  return <></>;
}

export function CreateBusinessPage() {
  const [prefill] = useCreateBusinessPrefill();
  const joinBusinessId = useSearchParam(SearchParam.joinBusinessId);
  
  if ((!prefill || !prefill.description) && !joinBusinessId) {
    logger.error(LogSource.APP, `Missing Description in Prefill: ${prefill}`);
    return <Redirect to={Path.home} message='Missing Description'/>;
  }

  return (<Flex
    align='center'
    bg='grayscale.offwhite'
    direction='column'
    justify='space-between'
    height='100%'
    minHeight='100vh'
    width='100%'
    px={12} py={4}
  >
    <Spacer flex='0 0 64px'/>
    <Image
      flex='0 0 fit-content'
      src={talawa}
      alt='Talawa'
      width='128px'
      mixBlendMode='multiply' // remove white background
    />
    <Spacer flex='0 0 112px' />
    <Image src={avatarShadow} alt='Brandi' boxSize='78px'/>
    <RequireUser OrElse={<SignInAnonymously />}>
      <AwaitBusiness>
        {/** 
         * AwaitBusiness just ensures business will be loaded *if* it exists.
         * CreateBusiness will *conditionally* create it if not.
        */}
        <Spacer flex='0 0 32px'/>
        <CreateBusiness flex='0 0 fit-content' prefill={prefill || undefined} />
        <Spacer flex='0 0 64px'/>
      </AwaitBusiness>
    </RequireUser>
  </Flex>);
}
