import React, { useState, useEffect } from "react";

import { useApi } from "src/api";

import { useBusiness } from "src/db";
import { Path, useNavigateWithParams } from "src/nav";

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Button,
    Text,
    Textarea,
    VStack,
} from "@chakra-ui/react";
import { UpdateBusinessDifferentiatorRequest } from "src/api/messages/UpdateBusinessDifferentiator";

export function UpdateBusinessDifferentiatorPage() {
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [businessDifferentiator, setBusinessDifferentiator] = useState<string | null>(
        null
    );
    const navigate = useNavigateWithParams();
    const business = useBusiness()!;

    useEffect(() => {
        if (isUpdating) {
            return;
        }
        if (!!business) {
            setBusinessDifferentiator(business.businessDifferentiator || "");
        }
    }, [business, isUpdating]);

    const [api, surfaceKnownErrors] = useApi();

    const handleSaveAndContinue = () => {
        if (businessDifferentiator === undefined || businessDifferentiator === null) {
            return;
        }
        setIsUpdating(true);
        const requestData: UpdateBusinessDifferentiatorRequest = {
            businessId: business.id,
            businessDifferentiator: businessDifferentiator,
        };
        api
            .updateBusinessDifferentiator(requestData, surfaceKnownErrors)
            .then(() => {
                navigate({ to: Path.home });
            })
            .catch((error) => {
                console.error(error);
            }).finally(() => {
                setIsUpdating(false);
            });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newBusinessDifferentiatorValue = event.target.value;
        setBusinessDifferentiator(newBusinessDifferentiatorValue);
    };

    return (
        <HeaderFooterPage
            pageTitle="Review and Refine"
            backTo={Path.home}
            backLabel="Brand Hub"
        >
            <Card mb={12} p={6}>
                <CardHeader mb={2}>
                    <Text as="header" textStyle="titleForSection" align="center">
                        Business Differentiator
                    </Text>
                </CardHeader>
                <CardBody mb={2}>
                    <VStack align="center">
                        <Text textStyle="body" align="center" mb={2}>
                            A unique characteristic, advantage,
                            or attribute that sets your company apart.
                        </Text>
                        {!isUpdating ? (
                            <Textarea
                                placeholder="Write your business differentiator here"
                                size="lg"
                                width="100%"
                                height="170px"
                                resize="none"
                                value={businessDifferentiator || ""}
                                onChange={handleInputChange}
                            />
                        ) : (
                            <Text textStyle="bodySmall" align="center">
                                Your business differentiator is loading...
                            </Text>
                        )}
                    </VStack>
                </CardBody>
                <CardFooter>
                    <Button
                        colorScheme="primary"
                        variant="fill"
                        minWidth="200px"
                        marginTop={'1rem'}
                        mx="auto"
                        onClick={handleSaveAndContinue}
                        isLoading={isUpdating || businessDifferentiator === null}
                    >
                        Save And Continue
                    </Button>
                </CardFooter>
            </Card>
        </HeaderFooterPage>
    );
}
