import {
  DocumentSnapshot,
  FirestoreDataConverter,
  SnapshotOptions,
} from "firebase/firestore";

export interface UserInvitationDocument {
  id: string;
  businessId: string;
  createdAt: Date;
  modifiedAt: Date;
  email: string;
  expiresAt: Date;
  role: string;
  status: string;
  userId: string;
}

export const userInvitationsConverter: FirestoreDataConverter<UserInvitationDocument> =
  {
    toFirestore(document: UserInvitationDocument) {
      return {
        id: document.id,
        businessId: document.businessId,
        createdAt: document.createdAt,
        modifiedAt: document.modifiedAt,
        email: document.email,
        expiresAt: document.expiresAt,
        role: document.role,
        status: document.status,
        userId: document.userId,
      };
    },

    fromFirestore(
      snapshot: DocumentSnapshot,
      options: SnapshotOptions
    ): UserInvitationDocument {
      const data = snapshot.data(options)!;
      return {
        id: snapshot.id,
        businessId: data.businessId,
        createdAt: data.createdAt.toDate(),
        modifiedAt: data.modifiedAt.toDate(),
        email: data.email,
        expiresAt: data.expiresAt.toDate(),
        role: data.role,
        status: data.status,
        userId: data.userId,
      };
    },
  };
