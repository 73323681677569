import { PropsWithChildren, useContext } from "react";
import { and, collection, or, orderBy, query, where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import {
  FirestoreContext,
  NotificationsContext,
  NotificationsContextProperties,
  unavailableCollectionContext,
} from "./contexts";
import { useBusinessId } from "./hooks";
import {
  notificationConverter,
  NotificationDocument,
} from "./model/notification";
import { useUser } from "src/auth";

const notificationsCollection = "notifications";

export function NotificationsProvider({ children }: PropsWithChildren) {
  const businessId = useBusinessId();
  const user = useUser();

  return (!user ? (
    <NotificationsContext.Provider value={unavailableCollectionContext}>
      {children}
    </NotificationsContext.Provider>
  ) : (
    <NotificationsProviderInner businessId={businessId} userId={user?.uid}>
      {children}
    </NotificationsProviderInner>
  ));
}

interface NotificationsProviderInnerProps {
  businessId: string | null;
  userId?: string;
}

export function NotificationsProviderInner({
  businessId,
  userId,
  children,
}: PropsWithChildren<NotificationsProviderInnerProps>) {
  const fs = useContext(FirestoreContext);

  console.log('userId', userId)

  // Query for recipientId == userId
const recipientNotificationsRef = query<NotificationDocument>(
  collection(fs, notificationsCollection).withConverter<NotificationDocument>(
    notificationConverter
  ),
  where("recipientId", "==", userId),
  orderBy("createdAt", "desc")
);

// Query for recipientId == null and businessId == businessId
const businessNotificationsRef = query<NotificationDocument>(
  collection(fs, notificationsCollection).withConverter<NotificationDocument>(
    notificationConverter
  ),
  where("recipientId", "==", null),
  where("businessId", "==", businessId),
  orderBy("createdAt", "desc")
);

  // Fetch data for both queries
const [recipientNotifications, recipientLoading] = useCollectionData(recipientNotificationsRef);
const [businessNotifications, businessLoading] = useCollectionData(businessNotificationsRef);

// Combine and sort results manually
const notifications = [
  ...(recipientNotifications || []),
  ...(businessNotifications || []),
].sort((a: NotificationDocument, b: NotificationDocument) => b.createdAt.getTime() - a.createdAt.getTime());

const providedValue: NotificationsContextProperties = {
  collection: notifications,
  isLoading: recipientLoading || businessLoading,
};

  return (
    <NotificationsContext.Provider value={providedValue}>
      {children}
    </NotificationsContext.Provider>
  );
}
