import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  EmailAuthProvider,
  UserCredential,
  createUserWithEmailAndPassword,
  getRedirectResult,
  linkWithCredential,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Icon,
  IconButton,
  Input,
  InputRightElement,
  StackDivider,
  Text,
  VStack,
  useToast,
  InputGroup,
} from "@chakra-ui/react";
import { IoMdCloseCircle } from "react-icons/io";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { ApiMethods, useApi, useApiPending } from "src/api";
import { ToUpdateProfileInfo } from "src/api/messages/UpdateProfile";
import { useAuth, useUser } from "src/auth";
import { TermsOfService } from "src/components";
import { GoogleAuthButton, ResetPasswordButton } from "src/components/auth";
import { useProfile } from "src/db";
import { useEffectLate } from "src/util/async";
import { logger, LogSource } from "src/util/logger";
import {
  Redirect,
  Path,
  SearchParam,
  useNavigateWithParams,
  useRedirectParam,
  useSearchParam,
  useSearchParamState,
  withOutSearchParams,
} from "src/nav";

import { HeaderFooterPage } from "./wrappers/HeaderFooterPage";
import { AwaitProfile } from "./wrappers/AwaitProfile";

const removeRedirect = withOutSearchParams([SearchParam.redirectTo]);
const removeRedirectAndClaim = withOutSearchParams([
  SearchParam.redirectTo,
  SearchParam.claimBusiness,
]);

interface LoginPageProps {
  isSignup?: boolean;
}

export function LoginPage({ isSignup = false }: LoginPageProps) {
  const navigate = useNavigateWithParams();
  return (
    <HeaderFooterPage pageTitle={isSignup ? "Signup" : "Login"}>
      <Box float={"right"}>
        <IconButton
          aria-label="Close"
          icon={<Icon as={IoMdCloseCircle} boxSize="1.5em" />}
          onClick={() =>
            navigate({ to: Path.login, modifySearch: removeRedirect })
          }
        />
      </Box>
      <Flex align="center" direction="column" justify="flex-start" my={8}>
        <AwaitProfile>
          <LoginPageContent isSignup={isSignup} />
        </AwaitProfile>
      </Flex>
    </HeaderFooterPage>
  );
}

export function LoginPageContent({ isSignup = false }: LoginPageProps) {
  const toast = useToast();
  const redirectTo = useRedirectParam(Path.urlOnboarding);
  const [auth, errorHandler] = useAuth();
  const user = useUser();
  const profile = useProfile();
  const claimBusinessId = useSearchParam(SearchParam.claimBusiness);
  const [hasCopiedProfileInfo, setHasCopiedProfileInfo] =
    useState<boolean>(false);

  const joinBusinessIdParam = useSearchParam(SearchParam.joinBusinessId);


  // Check for a redirect result and show error if needed.
  useEffect(() => {
    getRedirectResult(auth!).catch(errorHandler);
  }, [auth, errorHandler]);

  // Note: *If* we had more than one auth provider,
  // there should be a flag to show the buttons first regardless.
  if (!user || user!.isAnonymous) {
    return (
      <VStack spacing={4} divider={<StackDivider />}>
        <VStack align="center">
          <GoogleAuthButton isSignup={isSignup} />;
          {/** TODO: Other auth providers here */}
        </VStack>
        <EmailPasswordForm isSignup={isSignup} joinBusinessId={joinBusinessIdParam} />
        {isSignup ? (
          <Text>
            Already have an account?&nbsp;
            <Link
              to={{
                pathname: Path.login,
                search: joinBusinessIdParam
                  ? `?${SearchParam.joinBusinessId}=${joinBusinessIdParam}`
                  : "",
              }}
            >
              <em>Login</em>
            </Link>
          </Text>
        ) : (
          <Text>
            Don't have an account?&nbsp;
            <Link to={Path.signup}>
              <em>Signup</em>
            </Link>
          </Text>
        )}
      </VStack>
    );
  }

  if (isSignup) {
    if (!profile) {
      return <CreateProfile userId={user!.uid} />;
    } else if (!hasCopiedProfileInfo) {
      return (
        <UpdateProfile
          hasUpdated={hasCopiedProfileInfo}
          setHasUpdated={setHasCopiedProfileInfo}
        />
      );
    }
  } else {
    if (!profile) {
      logger.warn(LogSource.APP, "No profile exists!");
      toast({
        status: "error",
        description: "No profile exists. Try signing up",
      });
      return <></>;
    }
  }

  if (!profile.termsOfService.accepted || !profile.privacyPolicy.accepted) {
    return <TermsOfService />;
  }

  if (!!claimBusinessId) {
    return (
      <ClaimBusiness profileId={profile!.id} businessId={claimBusinessId} />
    );
  }

  return (
    <Redirect
      message="Successfully Logged In"
      to={redirectTo}
      modifySearch={removeRedirectAndClaim}
    />
  );
}

interface AuthButtonProps {
  isSignup?: boolean; // default false
  joinBusinessId?: string | null;
}

function EmailPasswordForm({ isSignup = false, joinBusinessId }: AuthButtonProps) {
  const [auth, errorHandler] = useAuth();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<boolean>(
    !isSignup
  );
  const [confirmedPasswordError, setConfirmedPasswordError] =
    useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const buttonText = isSignup ? "Sign Up" : "Login";
  const [passwordVisible, setPasswordVisible] = useState(false);

  const navigate = useNavigateWithParams();

  const doSignIn = useCallback(() => {
    setEmailError("");
    setPasswordError("");
    setConfirmedPasswordError("");
    if (!!email && !!password && (confirmedPassword || !isSignup)) {
      setIsSubmitting(true);
      let authPromise: Promise<UserCredential>;
      if (isSignup) {
        if (!!auth && !!auth.currentUser) {
          const credential = EmailAuthProvider.credential(email, password);
          authPromise = linkWithCredential(auth.currentUser, credential);
        } else {
          authPromise = createUserWithEmailAndPassword(
            auth,
            email,
            password
          ).then(async (credential) => {
            await sendEmailVerification(credential.user);
            return credential;
          });
        }
      } else {
        authPromise = signInWithEmailAndPassword(auth, email, password);
      }
      authPromise
        .then((_userCredential) => {
          if(joinBusinessId) {
            return navigate({ to: Path.create });
          }
          window.location.reload();
        })
        .catch(errorHandler)
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      if (!email) {
        setEmailError("Email is required");
      }
      if (!password) {
        setPasswordError("Password is required");
      }
      if (!confirmedPassword) {
        setConfirmedPasswordError("Passwords do not match");
      }
    }
  }, [
    isSignup,
    auth,
    email,
    password,
    confirmedPassword,
    errorHandler,
    setIsSubmitting,
    setEmailError,
    setPasswordError,
    setConfirmedPasswordError,
  ]);

  const promptEmailForReset = useCallback(() => {
    setEmailError("");
    setPasswordError("");
    setConfirmedPasswordError("");
    if (!!email) {
      return;
    }
    setEmailError("Enter an email to reset password");
  }, [email, setEmailError, setPasswordError, setConfirmedPasswordError]);

  const toggleVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <VStack align="center">
      <FormControl isInvalid={!!emailError} width="100%">
        <FormLabel>Email address</FormLabel>
        <Input
          type="email"
          variant="outline"
          bg="grayscale.white"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <FormErrorMessage>{emailError}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!passwordError} width="100%">
        <FormLabel>Password</FormLabel>
        <InputGroup>
          <Input
            type={passwordVisible ? "text" : "password"}
            variant="outline"
            bg="grayscale.white"
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
              setConfirmedPasswordError("");
            }}
          />
          <InputRightElement>
            {passwordVisible ? (
              <FaEyeSlash onClick={toggleVisibility} />
            ) : (
              <FaEye onClick={toggleVisibility} />
            )}
          </InputRightElement>
        </InputGroup>
        <FormHelperText>Six characters or more</FormHelperText>
        <FormErrorMessage color="red">{passwordError}</FormErrorMessage>
      </FormControl>
      {isSignup && (
        <FormControl isInvalid={!!confirmedPasswordError} width="100%">
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <Input
              type={passwordVisible ? "text" : "password"}
              variant="outline"
              bg="grayscale.white"
              onChange={(e) => {
                setConfirmedPassword(e.target.value === password);
                setPasswordError("");
                setConfirmedPasswordError("");
              }}
            />
            <InputRightElement>
              {passwordVisible ? (
                <FaEyeSlash onClick={toggleVisibility} />
              ) : (
                <FaEye onClick={toggleVisibility} />
              )}
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage color="red">
            {confirmedPasswordError}
          </FormErrorMessage>
        </FormControl>
      )}
      <Button
        variant="fill"
        colorScheme="primary"
        width="100%"
        isLoading={isSubmitting}
        loadingText={buttonText}
        onClick={() => doSignIn()}
      >
        {buttonText}
      </Button>

      <ResetPasswordButton
        email={email}
        onOpen={promptEmailForReset}
        logoutAfter={false} // User is already logged out
        width="100%"
      >
        Forgot Password?
      </ResetPasswordButton>
    </VStack>
  );
}

interface CreateProfileProps {
  userId: string;
}
function CreateProfile({ userId }: CreateProfileProps) {
  const [api, surfaceKnownErrors] = useApi();
  const [userMessage, setUserMessage] = useState("");
  const isCreatingProfile = useApiPending(ApiMethods.CREATE_PROFILE);

  const createProfile = useCallback(() => {
    if (isCreatingProfile) {
      return;
    }
    setUserMessage("Creating profile...");
    api.createProfile({ userId }, surfaceKnownErrors).then(() => {
      setUserMessage("Created profile!");
    });
  }, [api, surfaceKnownErrors, isCreatingProfile, userId]);
  useEffectLate(createProfile);

  return (
    <Text display="block">
      <em>{userMessage}</em>
    </Text>
  );
}

interface ClaimBusinessProps {
  profileId: string;
  businessId: string;
}
function ClaimBusiness({ profileId, businessId }: ClaimBusinessProps) {
  const [api, surfaceKnownErrors] = useApi();
  const [userMessage, setUserMessage] = useState("");
  const isClaimingBusiness = useApiPending(ApiMethods.CLAIM_BUSINESS);
  const [claimBusinessId, setClaimBusinessId] = useSearchParamState(
    SearchParam.claimBusiness
  );

  const claimBusiness = useCallback(() => {
    if (isClaimingBusiness || !claimBusinessId) {
      return;
    }
    setUserMessage("Claiming your business...");
    api
      .claimBusiness({ profileId, businessId }, surfaceKnownErrors, () => {
        setClaimBusinessId(null);
      })
      .then(() => {
        setUserMessage("Claimed your business!");
      });
  }, [
    api,
    surfaceKnownErrors,
    profileId,
    businessId,
    isClaimingBusiness,
    claimBusinessId,
    setClaimBusinessId,
  ]);
  useEffectLate(claimBusiness);

  return (
    <Text display="block">
      <em>{userMessage}</em>
    </Text>
  );
}

interface UpdateProfileProps {
  hasUpdated: boolean;
  setHasUpdated: (newValue: boolean) => void;
}
function UpdateProfile({ hasUpdated, setHasUpdated }: UpdateProfileProps) {
  const [api, surfaceKnownErrors] = useApi();
  const user = useUser(true)!;
  const profile = useProfile(true)!;
  const [userMessage, setUserMessage] = useState("");
  const isUpdatingProfile = useApiPending(ApiMethods.UPDATE_PROFILE);

  const updateProfile = useCallback(() => {
    if (isUpdatingProfile || hasUpdated) {
      return;
    }
    setUserMessage("Updating profile...");
    const newInfo: ToUpdateProfileInfo = {};
    if (!!user.displayName) {
      newInfo.name = user.displayName;
    }
    if (!!user.photoURL) {
      newInfo.profileImageUrl = user.photoURL;
    }
    api
      .updateProfile(
        {
          profileId: profile.id,
          toUpdateProfileInfo: newInfo,
        },
        surfaceKnownErrors
      )
      .then(() => {
        setHasUpdated(true);
        setUserMessage("Updated profile!");
      });
  }, [
    api,
    surfaceKnownErrors,
    isUpdatingProfile,
    user,
    profile,
    hasUpdated,
    setHasUpdated,
  ]);
  useEffectLate(updateProfile);

  return (
    <Text display="block">
      <em>{userMessage}</em>
    </Text>
  );
}
