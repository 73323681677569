import React, { PropsWithChildren, useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Avatar,
  BoxProps,
  Divider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  List,
  ListIcon,
  ListItem,
  Spacer,
  Text,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { FaShapes, FaBolt, FaPaintBrush, FaUser } from "react-icons/fa";
import { MdMenu, MdHome } from "react-icons/md";
import { VscBell } from "react-icons/vsc";
import { MdClose } from "react-icons/md";

import { UserContext } from "src/auth";
import { CreditsIcon } from "src/components";
import { useProfile, useProfileCredits, useBusiness } from "src/db";
import { talawa, talawaMobile, menuBackground } from "src/images";
import { Path, SearchParam } from "src/nav";
import { useScreenDetector } from "./hooks/useScreenDetector";
import { useNotifications } from "src/db/hooks";
import NotificationsModal from "./NotificationsModal";

const bsColor = "var(--chakra-colors-primary-light)";
const bsHeight = "120px";
const containerBoxShadow =
  "" +
  `inset 0 ${bsHeight} ${bsHeight} -${bsHeight} ${bsColor},` + // Top
  `inset 0 -${bsHeight} ${bsHeight} -${bsHeight} ${bsColor}`; // Bottom

interface NavLinkProps extends PropsWithChildren {
  to: Path;
}

interface HeaderMenuProps extends BoxProps {
  hideLogo?: boolean;
}
export function HeaderMenu({ hideLogo = false, ...boxProps }: HeaderMenuProps) {
  const { pathname, search } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const user = useContext(UserContext);
  const profile = useProfile();
  const business = useBusiness();
  const profileCredits = useProfileCredits();
  const isSignedIn = !!user && !user.isAnonymous;
  const isLoginPage = pathname === Path.login;
  const isSignupPage = pathname === Path.signup;
  const displayName = profile?.profileInfo?.name || user?.displayName || "";
  const fallbackDisplayName = user?.email || "(anonymous)";
  const photoUrl =
    profile?.profileInfo?.profileImageInfo?.imageUrl || user?.photoURL || "";

  const [notificationsModal, setNotificationsModal] = useState<boolean>(false);

  const notifications = useNotifications();

  const { isMobile } = useScreenDetector();

  function NavLink({ to, children }: NavLinkProps) {
    if (to === pathname) {
      return (
        <Link to={`${to}${search}`} aria-current={true} onClick={onClose}>
          {children}
        </Link>
      );
    } else {
      return <Link to={`${to}${search}`}>{children}</Link>;
    }
  }

  const openNotifications = () => {
    setNotificationsModal(true);
  };

  return (
    <>
      <Flex as="nav" direction="row" align="center" {...boxProps}>
        {!hideLogo ? (
          <Link to={Path.newLanding}>
            <Image
              src={isMobile ? talawaMobile : talawa}
              alt="Talawa"
              mixBlendMode="multiply"
              height="24px"
            />
          </Link>
        ) : null}
        <Spacer />
        <HStack
          flex="0 0 fit-content"
          spacing={4}
          divider={
            <Divider
              orientation="vertical"
              height={4}
              borderColor="grayscale.medium"
            />
          }
        >
          {isSignedIn ? (
            profileCredits !== null && (
              <NavLink to={Path.credits}>
                <CreditsIcon>{profileCredits}</CreditsIcon>
              </NavLink>
            )
          ) : (
            <HStack
              flex="0 0 fit-content"
              divider={
                <Text as="span" color="text.placeholder" mx={2}>
                  or
                </Text>
              }
            >
              {!isLoginPage && (
                <NavLink to={Path.login}>
                  <Text as="span" decoration="underline">
                    Login
                  </Text>
                </NavLink>
              )}
              {!isSignupPage && (
                <NavLink to={Path.signup}>
                  <Text as="span" decoration="underline">
                    Sign Up
                  </Text>
                </NavLink>
              )}
            </HStack>
          )}
          {isSignedIn && (
            <Box position="relative">
              <IconButton
                icon={
                  <Icon as={VscBell} color={"primary.dark"} boxSize="20px" />
                }
                aria-label="Notifications"
                onClick={openNotifications}
                bg={"transparent"}
              />
              {Boolean(notifications?.length) &&
                notifications?.some((n) => !n.read) && (
                  <Box
                    borderRadius="50%"
                    bg="error"
                    width="8px"
                    height="8px"
                    position="absolute"
                    top="13px"
                    right="1px"
                  />
                )}
            </Box>
          )}
          <IconButton
            icon={<Icon as={MdMenu} color={"primary.dark"} boxSize="32px" />}
            aria-label="Menu"
            ref={btnRef}
            onClick={onOpen}
            bg={"transparent"}
          ></IconButton>
        </HStack>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent
            px={8}
            py={10}
            sx={{
              backgroundColor: "grayscale.offwhite",
              backgroundImage: menuBackground,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              boxShadow: containerBoxShadow,
            }}
          >
            <DrawerCloseButton top={4} right={4}>
              <Icon as={MdClose} boxSize="1.5em" />
            </DrawerCloseButton>
            <List textStyle={"buttonXLarge"} spacing={4}>
              <ListItem display="flex" alignItems="center">
                <ListIcon as={MdHome}></ListIcon>
                <NavLink to={!!profile ? Path.home : Path.landing}>
                  Brand Hub
                </NavLink>
              </ListItem>
              {!!business && (
                <>
                  <ListItem display="flex" alignItems="center">
                    <ListIcon textStyle="buttonLarge" as={FaShapes}></ListIcon>
                    <NavLink to={Path.brandkit}>Brand Kit</NavLink>
                  </ListItem>
                  <ListItem display="flex" alignItems="center">
                    <ListIcon as={FaBolt}></ListIcon>
                    <NavLink to={Path.brandactions}>Brand Actions</NavLink>
                  </ListItem>
                  <ListItem display="flex" alignItems="center">
                    <ListIcon as={FaPaintBrush}></ListIcon>
                    <Link
                      to={{
                        pathname: Path.home,
                        search: `?${SearchParam.focusSection}=brandPrints`,
                      }}
                    >
                      Brand Creations
                    </Link>
                  </ListItem>
                  <ListItem display="flex" alignItems="center">
                    <ListIcon as={FaUser}></ListIcon>
                    <NavLink to={Path.members}>Members</NavLink>
                  </ListItem>
                </>
              )}
            </List>
            <Divider width="80%" orientation="horizontal" mt={4} mb={4} />
            <List textStyle={"buttonLarge"} spacing={2}>
              <ListItem>
                <Link
                  to={`mailto:community@talawa.ai?subject='Getting in touch with Talawa'`}
                >
                  Contact
                </Link>
              </ListItem>
              <ListItem>
                <NavLink to={Path.pricing}>
                  <em>Pricing</em>
                </NavLink>
              </ListItem>
            </List>
            <Spacer />
            <Divider orientation="horizontal" mb={4} />
            <List textStyle={"bodyLarge"}>
              {isSignedIn && (
                <ListItem>
                  <NavLink to={Path.account}>
                    <HStack mb={2}>
                      <Avatar name={displayName} src={photoUrl} size="xs" />
                      <Text textStyle="body2">
                        View <em>{displayName || fallbackDisplayName}</em>{" "}
                        account
                      </Text>
                    </HStack>
                  </NavLink>
                </ListItem>
              )}
              {isSignedIn && (
                <ListItem>
                  {/* // TODO: workaround to avoid "infinite loop" in the logout page.
              // It seems that if not "reloadDocument", that happens. */}
                  <Link reloadDocument to={Path.logout}>
                    <em>Logout</em>
                  </Link>
                </ListItem>
              )}
              {!isSignedIn && (
                <ListItem>
                  <NavLink to={Path.login}>
                    <em>Login</em>
                  </NavLink>
                </ListItem>
              )}
              {!isSignedIn && (
                <ListItem>
                  <NavLink to={Path.signup}>
                    <em>Sign Up</em>
                  </NavLink>
                </ListItem>
              )}
            </List>
          </DrawerContent>
        </Drawer>
      </Flex>
      {notificationsModal && (
        <NotificationsModal
          isOpen={notificationsModal}
          handleClose={() => setNotificationsModal(false)}
          notifications={notifications}
        />
      )}
    </>
  );
}
