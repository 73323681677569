import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { Note, NotesContextType } from './interfaces';

const NotesContext = createContext<NotesContextType | undefined>(undefined);

const useDrafts = () => {
  const [drafts, setDrafts] = useState<Note[]>(() => {
    const sessionDrafts = sessionStorage.getItem('drafts');
    return sessionDrafts ? JSON.parse(sessionDrafts) : [];
  });

  useEffect(() => {
    sessionStorage.setItem('drafts', JSON.stringify(drafts));
  }, [drafts]);

  const updateDrafts = (newDrafts: Note[]) => {
    setDrafts(newDrafts);
  };

  return [drafts, updateDrafts] as const;
};

export const useNotes = (): NotesContextType => {
  const context = useContext(NotesContext);
  if (context === undefined) {
    throw new Error('useNotes must be used within a NotesProvider');
  }
  return context;
};

export const NotesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notes, setNotes] = useState<Note[]>(() => {
    const savedNotes = localStorage.getItem('notes');
    const sessionDrafts = sessionStorage.getItem('drafts');
    const drafts: Note[] = sessionDrafts ? JSON.parse(sessionDrafts) : [];
    return savedNotes ? JSON.parse(savedNotes).concat(drafts) : drafts;
  });

  const [, updateDrafts] = useDrafts();

  const saveToLocalStorage = (notes: Note[]) => {
    const savedNotes = notes.filter(note => !note.isDraft);
    localStorage.setItem('notes', JSON.stringify(savedNotes));
  };

  const addNote = (note: Note) => {
    setNotes((prevNotes) => {
      const updatedNotes = [...prevNotes, note];
      saveToLocalStorage(updatedNotes);
      updateDrafts(updatedNotes.filter(note => note.isDraft));
      return updatedNotes;
    });
  };

  const updateNote = (id: number, newText: string) => {
    setNotes((prevNotes) => {
      const updatedNotes = prevNotes.map((note) =>
        note.id === id ? { ...note, text: newText } : note
      );
      saveToLocalStorage(updatedNotes);
      updateDrafts(updatedNotes.filter(note => note.isDraft));
      return updatedNotes;
    });
  };

  const deleteNote = (id: number) => {
    setNotes((prevNotes) => {
      const updatedNotes = prevNotes.filter((note) => note.id !== id);
      saveToLocalStorage(updatedNotes);
      updateDrafts(updatedNotes.filter(note => note.isDraft));
      return updatedNotes;
    });
  };

  const saveNote = (id: number) => {
    setNotes((prevNotes) => {
      const updatedNotes = prevNotes.map((note) =>
        note.id === id ? { ...note, isDraft: false } : note
      );
      saveToLocalStorage(updatedNotes);
      updateDrafts(updatedNotes.filter(note => note.isDraft));
      return updatedNotes;
    });
  };

  useEffect(() => {
    saveToLocalStorage(notes);
  }, [notes]);

  return (
    <NotesContext.Provider value={{ notes, addNote, updateNote, deleteNote, saveNote }}>
      {children}
    </NotesContext.Provider>
  );
};
