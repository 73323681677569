import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useApi } from "src/api";
import { useBusinessId } from "src/db";
import { Path, useNavigateWithParams } from "src/nav";

interface RemoveMemberModalProps {
  isOpen: boolean;
  leaveTeam?: boolean;
  profileId: string | null;
  handleClose: () => void;
}

const RemoveMemberModal = ({
  isOpen,
  leaveTeam,
  profileId,
  handleClose,
}: RemoveMemberModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [api, surfaceKnownErrors] = useApi();

  const businessId = useBusinessId()!;
  const toast = useToast();
  const navigate = useNavigateWithParams();

  const removeMember = () => {
    if (isSubmitting || !profileId) {
      return;
    }
    setIsSubmitting(true);
    api
      .removeMember(
        {
          businessId,
          profileId,
        },
        surfaceKnownErrors
      )
      .then(() => {
        handleClose();
        if (!leaveTeam) {
          toast({
            description: "Member removed successfully",
            duration: 3000,
          });
        } else {
          navigate({ to: Path.logout });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose} size="xs">
      <ModalOverlay
        bg="blackAlpha.500"
      />
      <ModalContent borderRadius="16px">
        <ModalHeader color="gray.dark" fontSize="18px">{leaveTeam ? "Leave Team?" : "Remove Member?"}</ModalHeader>
        <ModalCloseButton mt="4" mr="4" />
        <ModalBody>
          <Text textStyle="bodySmall" color="gray.medium" fontSize="13px">
            {leaveTeam
              ? "Members won't be notified"
              : "This means the member will be removed from the group."}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Box display="flex" flexDirection="column" width="100%" gap="4">
            <Button onClick={removeMember} isLoading={isSubmitting} variant="danger" width="100%" borderRadius={7}>
              {leaveTeam ? 'Leave' : 'Remove Member'}
            </Button>
            <Button colorScheme='primary' onClick={handleClose} variant="outline" width="100%" borderRadius={7}>
            {leaveTeam ? 'Stay' : 'Keep Member'}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RemoveMemberModal;
