import React, { FC, SyntheticEvent, useCallback, useState } from 'react';

import {
  BoxProps,
  ButtonProps,
  HStack,
  Icon,
  IconButton,
  Spinner,
  StackDivider,
  Tooltip,
} from '@chakra-ui/react';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from "react-icons/io";
import { IoArrowUndoCircleOutline } from "react-icons/io5";

import { AssetDocument, useIsBrandKitLocked } from 'src/db';

import { useSelectAsset, useUnselectAsset, useRemoveAsset } from './hooks';
import { UpdateTextAssetButtonProps } from '../updateAsset/UpdateTextAsset';
import { useMandatoryAssetsCompleted } from '../../BrandKitProgress';

export interface AssetActionsProps extends BoxProps {
  asset: AssetDocument;
  UpdateAssetButton?: FC<UpdateTextAssetButtonProps>;
}
export function AssetActions({asset, UpdateAssetButton, ...boxProps}: AssetActionsProps) {
  const isBrandKitLocked = useIsBrandKitLocked();
  const selectAsset = useSelectAsset();
  const unselectAsset = useUnselectAsset();
  const removeAsset = useRemoveAsset();
  const [awaitingApi, setAwaitingApi] = useState<boolean>(false);

  const mandatoryAssetsCompleted = useMandatoryAssetsCompleted()

  const handleSelectAsset = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (awaitingApi) { return; }
    selectAsset(asset, setAwaitingApi);
  }, [asset, awaitingApi, selectAsset]);

  const handleUnselectAsset = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (awaitingApi) { return; }
    unselectAsset(asset, setAwaitingApi);
  }, [asset, awaitingApi, unselectAsset]);

  const handleRemoveAsset = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    if (awaitingApi) { return; }
    removeAsset(asset, setAwaitingApi);
  }, [asset, awaitingApi, removeAsset]);

  if (isBrandKitLocked) {
    return <></>
  }

  if (awaitingApi) {
    return <HStack flex='0 0 fit-content' {...boxProps}>
      <Spinner flex='0 0 24px' />
    </HStack>
  }

  return (
    <HStack 
      flex='0 0 fit-content'
      divider={<StackDivider borderColor='grayscale.medium' />}
      {...boxProps}
    >
      {asset.selected && 
        <UnselectAssetIconButton onClick={handleUnselectAsset}/>
      }
      {!asset.selected &&
        <RemoveAssetIconButton onClick={handleRemoveAsset}/>
      }
      {!asset.selected &&
        <SelectAssetIconButton onClick={handleSelectAsset}/>
      }
      {!!UpdateAssetButton && !mandatoryAssetsCompleted && <UpdateAssetButton asset={asset} />}
    </HStack>
  );
}

export function SelectAssetIconButton({ ...btnProps }: ButtonProps) {
  return (<Tooltip label='Select' placement='top'>
    <IconButton
      aria-label='Select'
      color='primary.dark'
      icon={<Icon as={IoIosCheckmarkCircleOutline} p={0} boxSize='24px'  />}
      height='fit-content'
      {...btnProps}
    />
  </Tooltip>);
}

export function UnselectAssetIconButton({ ...btnProps }: ButtonProps) {
  return (<Tooltip label='Unselect' placement='top'>
    <IconButton
      aria-label='Unselect'
      color='secondary.dark'
      icon={<Icon as={IoArrowUndoCircleOutline} boxSize='24px' />}
      height='fit-content'
      {...btnProps}
    />
  </Tooltip>);
}

export function RemoveAssetIconButton({ ...btnProps }: ButtonProps) {
  return (<Tooltip label='Remove' placement='top'>
    <IconButton
      aria-label='Remove'
      color='system.error.dark'
      icon={<Icon as={IoIosCloseCircleOutline}p={0} boxSize='24px' />}
      height='fit-content'
      {...btnProps}
    />
  </Tooltip>);
}
