import React, { useState, useCallback, memo } from 'react';
import { Box, Text, IconButton, Tooltip, Textarea, Stack, Flex } from '@chakra-ui/react';
import { MdDelete, MdSave, MdCheck, MdCancel } from 'react-icons/md';
import { TbEditCircle } from "react-icons/tb";
import { NoteItemProps } from './interfaces';

const NoteItem: React.FC<NoteItemProps> = memo(({ note, onSave, onDelete, onEdit }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(note.text);

  const handleEditChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditText(event.target.value);
  }, []);

  const handleEditSave = useCallback(() => {
    onEdit(note.id, editText);
    setIsEditing(false);
  }, [editText, note.id, onEdit]);

  return (
    <Box
      p={4}
      borderWidth={1}
      borderRadius="md"
      bg={note.isDraft ? 'gray.50' : 'white'}
      shadow="md"
      position="relative"
      overflow="hidden"
      mb={3}
    >
      {isEditing ? (
        <Stack spacing={3}>
          <Textarea
            value={editText}
            onChange={handleEditChange}
            rows={6}
            placeholder="Edit your note..."
            resize="none"
          />
          <Flex justify="flex-end" gap={2}>
            <Tooltip label="Save Edit" aria-label="Save Edit">
              <IconButton
                icon={<MdCheck />}
                colorScheme="teal"
                onClick={handleEditSave}
                aria-label="Save Edit"
              />
            </Tooltip>
            <Tooltip label="Cancel Edit" aria-label="Cancel Edit">
              <IconButton
                icon={<MdCancel />}
                colorScheme="red"
                onClick={() => setIsEditing(false)}
                aria-label="Cancel Edit"
              />
            </Tooltip>
          </Flex>
        </Stack>
      ) : (
        <>
          <Text as="div" whiteSpace="pre-line" mb={4} wordBreak="break-word">
            {note.text}
          </Text>
          <Flex mt={2} justify="flex-end" gap={2.5}>
            {note.isDraft ? (
              <Tooltip label="Save Note" aria-label="Save Note">
                <IconButton
                  icon={<MdSave />}
                  colorScheme="teal"
                  size="md"
                  onClick={() => onSave(note.id)}
                  aria-label="Save Note"
                />
              </Tooltip>
            ) : (
              <Tooltip label="Note Saved" aria-label="Note Saved">
                <IconButton
                  icon={<MdCheck color="green" />}
                  colorScheme="blank"
                  size="md"
                  aria-label="Note Saved"
                />
              </Tooltip>
            )}
            <Tooltip label="Edit Note" aria-label="Edit Note">
              <IconButton
                icon={<TbEditCircle />}
                colorScheme="blue"
                size="md"
                onClick={() => setIsEditing(true)}
                aria-label="Edit Note"
              />
            </Tooltip>
            <Tooltip label="Delete Note" aria-label="Delete Note">
              <IconButton
                icon={<MdDelete />}
                colorScheme="red"
                size="md"
                onClick={() => onDelete(note.id)}
                aria-label="Delete Note"
              />
            </Tooltip>
          </Flex>
        </>
      )}
    </Box>
  );
});

export default NoteItem;
