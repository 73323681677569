import { Button, Center, Text, useToast, UseToastOptions } from '@chakra-ui/react';

import { useTriggerPayWall } from 'src/components/paywall';
import { logger, LogSource, ErrorOptions } from 'src/util/logger';
import { useSendToErrorPage, ErrorPageCode } from 'src/pages';

import { ApiResponse } from '../messages/ApiResponse';

export type ErrorSurfacer = (err: ApiResponse<any>) => void;

const toLoggerError = (response: ApiResponse<any>): ErrorOptions =>  {
  return {
    code: response.errorType,
    message: response.errorUserMessage,
  }
}

export function useSurfaceKnownErrors(): ErrorSurfacer {
  const toast = useToast();
  const sendToErrorPage = useSendToErrorPage();

  const defaultProps: UseToastOptions = {
    status: 'warning',
    duration: null,
    isClosable: true,
  };

  return (response: ApiResponse<any>) => {
    const userMessage = response.errorUserMessage || '';
    if (!response.errorType) {
      logger.severe(LogSource.API, `MISSING errorType to surface: ${response}`);
      return;
    } else {
      switch (response.errorType) {
        case 'NOT_ENOUGH_CREDITS_FOR_ASSET_GENERATION': {
          toast({
            ...defaultProps,
            title: 'Out of Credits',
            description: <PurchaseCreditsDescription />,
          });
          return;
        }
        case 'MAX_ASSETS_SELECTED_FOR_ASSET_TYPE_REACHED':
        case 'BUSINESS_ALREADY_CLAIMED':
        case 'BUSINESS_CANNOT_BE_CLAIMED_ANONYMOUS_USER': {
          toast({
            ...defaultProps,
            description: userMessage,
          });
          return;
        }
        case 'PROFILE_DOES_NOT_EXIST': {
          logger.error(LogSource.API, `API Error: ${response}`, toLoggerError(response));
          sendToErrorPage(ErrorPageCode.PROFILE_DOES_NOT_EXIST);
          return;
        }
        case 'PROFILE_AND_BUSINESS_ASSOCIATED': {
          toast({
            ...defaultProps,
            status: 'info',
            description: "You're already linked to this brand",
          });
          return;
        }
        default: {
          toast({
            ...defaultProps,
            status: 'error',
            title: 'Something went wrong',
            description: <ContactSupport message={userMessage} />,
          });
          logger.severe(LogSource.API, `UNKNOWN API Error: ${response}`, toLoggerError(response));
        }
      }
    }
  }
}

function PurchaseCreditsDescription() {
  const toast = useToast();
  const triggerPayWall = useTriggerPayWall();

  return (<>
    <Text mb={2}>You do not have enough credits to generate assets</Text>
    <Center>
      <Button
        variant='fill'
        colorScheme='secondary'
        onClick={() => {
          triggerPayWall({});
          toast.closeAll();
        }}
      >Purchase more</Button>
    </Center>
  </>)
}

interface ContactSupportProps { message: string};
function ContactSupport({message}: ContactSupportProps) {
  return (<>
    <Text textStyle='body' mb={2}>Please try again or contact support</Text>
    <Text textStyle='bodySmall' mb={2}><em>Error Details:</em>&nbsp;{message}</Text>
  </>)
}
