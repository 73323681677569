import { DocumentSnapshot, FirestoreDataConverter, SnapshotOptions } from 'firebase/firestore';

// Jucntion Document


interface ProfileInfo {
  email: string,
  name: string
}
interface Profile {
  id: string,
  profileId: string,
  profileInfo: ProfileInfo
}
export interface ProfileBusinessDocument {
  id: string;
  profileId: string, // foreign
  businessId: string, // foreign
  role: string,
  profile?: Profile
}

export const profileBusinessConverter: FirestoreDataConverter<ProfileBusinessDocument> = {
  toFirestore (document: ProfileBusinessDocument) {
    return {
      profileId: document.profileId,
      businessId: document.businessId,
    };
  },

  fromFirestore (snapshot: DocumentSnapshot, options: SnapshotOptions): ProfileBusinessDocument{
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      profileId: data.profileId,
      businessId: data.businessId,
      role: data.role
    };
  },
};
