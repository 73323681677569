import { useContext, SetStateAction } from 'react';

import { useIsUserNonAnonymous } from 'src/auth';
import {
  ProfileContext,
  BusinessContext,
  AssetsContext,
  AssetContextProperties,
  ActionsContext,
  ActionContextProperties,
  ReportsContextProperties,
  ReportsContext,
  MembersContext,
  MembersContextProperties,
  NotificationsContext,
} from './contexts';
import { ActionTypes  } from './model/action';
import { AssetTypes } from './model/asset';
import { ProfileDocument, ProfileInfo, ProfileTier } from './model/profile';
import { BusinessDocument } from './model/business';
import { NameAssetDocument } from './model/assets/nameAsset';
import { ReportDocument, ReportStatus } from './model/report';
import { ProfileBusinessDocument } from './model/profileBusiness';
import { NotificationDocument } from './model/notification';

export function useProfileId() : string|null {
  return useContext(ProfileContext).profileId;
}

export function useProfile(allowAnonymous: boolean = true) : ProfileDocument|null {
  const profile =  useContext(ProfileContext).profile;
  const isNonAnonymous = useIsUserNonAnonymous();
  if (allowAnonymous) {
    return profile;
  } else {
    return isNonAnonymous ? profile : null;
  }
}

export function useProfileInfo() : ProfileInfo|null {
  const profile = useProfile(true);
  if (!profile) return null;
  return profile.profileInfo;
}

export function useProfileTier() : ProfileTier|null {
  const profile = useProfile(true);
  if (!profile) return null;
  return profile.tier;
}

export function useProfileCredits() : number|null {
  const profileContext =  useContext(ProfileContext);
  return profileContext.credits;
} 

export function useNotifications() : NotificationDocument[]|null {
  const notificationsContext =  useContext(NotificationsContext);
  return notificationsContext.collection;
} 

// Whether the current profile is on free tier.
// Defaults to true if profile is not found.
export function useIsFreeTier() : boolean {
  const tier = useProfileTier();
  if (!tier) { return true; }
  return tier === ProfileTier.FREE;
}

export function useBusiness() : BusinessDocument|null {
  return useContext(BusinessContext).business;
}

export function useBusinesses() : ProfileBusinessDocument[] | undefined {
  return useContext(BusinessContext).businesses;
}

export function useBusinessId() : string|null {
  return useContext(BusinessContext).businessId;
}

export function useSetSelectedBusinessId() {
  return useContext(BusinessContext).setSelectedBusinessId;
}

export function useBusinessName() : string|null {
  const selectedName = useAssetCollection<NameAssetDocument>(AssetTypes.name).find(
    (name: NameAssetDocument) => {return name.selected;}
  );
  if (!selectedName) { return null; }
  return selectedName!.value.name;
}

export function useIsBrandKitLocked() : boolean {
  const business = useContext(BusinessContext).business;
  return business?.isBrandKitLocked || false;
}

export function useAssetContext<DocumentType>(assetType: AssetTypes) : AssetContextProperties<DocumentType> {
  return useContext(AssetsContext)[assetType] as AssetContextProperties<DocumentType>;
}  

export function useAssetCollection<DocumentType>(assetType: AssetTypes) : DocumentType[] {
  return useAssetContext<DocumentType>(assetType).collection;
}

export function useActionContext<DocumentType>(actionType: ActionTypes) : ActionContextProperties<DocumentType> {
  return useContext(ActionsContext)[actionType] as ActionContextProperties<DocumentType>;
}  

export function useActionCollection<DocumentType>(actionType: ActionTypes) : DocumentType[] {
  return useActionContext<DocumentType>(actionType).collection;
}

export function useReportsContext() : ReportsContextProperties {
  return useContext(ReportsContext) as ReportsContextProperties;
}  

// Filters deleted reports
export function useReportCollection() : ReportDocument[] {
  return useReportsContext().collection.filter(r => r.status !== ReportStatus.DELETED);
}

export function useSelectedDate(): [Date, (date: Date|SetStateAction<Date>) => void] {
  const { selectedDate, setSelectedDate } = useContext(ActionsContext);
  return [selectedDate, setSelectedDate];
}

// Members hooks
export function useMembersContext(): MembersContextProperties {
  return useContext(MembersContext)
}

export function useMembersCollection(): ProfileBusinessDocument[] {
  return useMembersContext().collection;
}


