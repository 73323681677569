import React from 'react';
import { NotesProvider } from './NotesContext';
import NotesManager from './NotesManager';

const NotesContainer: React.FC = () => {
  return (
    <NotesProvider>
      <NotesManager />
    </NotesProvider>
  );
};

export default NotesContainer;