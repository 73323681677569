import {
  Box,
  Text,
  Input,
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useToast,
  HStack,
  useRadioGroup,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useApi } from "src/api";
import RadioCard from "src/components/RadioCard";
import { ProfileBusinessDocument, useBusinessId } from "src/db";
import {
  UserInvitationDocument,
} from "src/db/model/userInvitation";

interface AddMemberModalProps {
  isOpen: boolean;
  pendingInvitations?: UserInvitationDocument[]
  existingMembers?: ProfileBusinessDocument[]
  handleClose: () => void;
  fetchPendingInvitations: () => void
}

type Member = {
  email: string;
  role: string;
};

export const ROLES = {
  owner: "owner",
  admin: "admin",
  editor: "editor",
  viewer: "viewer",
};

const ROLE_OPTIONS = Object.keys(ROLES);

const [, defaultRole] = ROLE_OPTIONS;

const AddMemberModal = ({ isOpen, pendingInvitations, existingMembers, handleClose, fetchPendingInvitations }: AddMemberModalProps) => {
  const [members, setMembers] = useState<Member[]>([]);
  const [emailInput, setEmailInput] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [emailExist, setEmailExist] = useState<boolean>(false);
  const [memberExist, setMemberExist] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [role, setRole] = useState<string>(defaultRole);

  const businessId = useBusinessId()!;
  const toast = useToast();

  const [api, surfaceKnownErrors] = useApi();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const addEmail = () => {
    if (existingMembers?.some((em) => em.profile?.profileInfo.email === emailInput)) {
      return setMemberExist(true);
    } else {
      setMemberExist(false);
    }

    if (pendingInvitations?.some((ui) => ui.email === emailInput)) {
      return setEmailExist(true);
    } else {
      setEmailExist(false);
    }
    if (
      emailRegex.test(emailInput) &&
      !members.some((m) => m.email === emailInput)
    ) {
      setMembers([...members, { email: emailInput, role }]);
      setEmailInput("");
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.target.value);
    setIsEmailValid(emailRegex.test(e.target.value));
  };

  const removeEmail = (email: string) => {
    setMembers(members.filter((e) => e.email !== email));
  };

  const sendInvites = () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    api
      .inviteMembers(
        {
          businessId,
          members,
        },
        surfaceKnownErrors
      )
      .then(() => {
        fetchPendingInvitations();
        handleClose();
        setMembers([]);
        toast({
          description: "Members invited successfully",
          duration: 3000,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "role",
    defaultValue: ROLES.admin,
    onChange: setRole,
  });

  const group = getRootProps();

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="full">
      <ModalOverlay />
      <ModalContent
        mt="100px"
        borderRadius="16px 16px 0 0"
        maxH="75%"
        maxWidth={800}
      >
        <ModalHeader>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box position="absolute" left="4">
              <IconButton
                icon={
                  <Icon
                    as={MdKeyboardArrowLeft}
                    color={"gray.medium"}
                    boxSize="32px"
                  />
                }
                aria-label="Menu"
                onClick={handleClose}
                bg={"transparent"}
              />
            </Box>
            <Box>
              <Text color="gray.dark">Invite to team</Text>
            </Box>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box my={4}>
            <Input
              type="text"
              placeholder="Invite by email"
              value={emailInput}
              onChange={handleEmailChange}
              isInvalid={!isEmailValid || emailExist}
            />
            {!isEmailValid && (
              <Text textStyle="body" color="red.500" mt={2}>
                Please enter a valid email address.
              </Text>
            )}
            {emailExist && (
              <Text textStyle="body" color="red.500" mt={2}>
                There is already an invitation for this email
              </Text>
            )}
            {memberExist && (
              <Text textStyle="body" color="red.500" mt={2}>
                There is already a member with this email
              </Text>
            )}
            <Box pt="4">
              <HStack
                {...group}
                gap={0}
                borderRadius={8}
                borderWidth={1}
                borderStyle="solid"
                borderColor="gray.darkLightest"
                display="flex"
              >
                {ROLE_OPTIONS.map((value) => {
                  const radio = getRadioProps({ value });
                  return (
                    <RadioCard key={value} {...radio} width="100%">
                      {value}
                    </RadioCard>
                  );
                })}
              </HStack>
            </Box>
          </Box>
          <Box>
            <Button
              colorScheme="primary"
              variant="outline"
              width="100%"
              borderRadius="7px"
              onClick={addEmail}
              isDisabled={!Boolean(emailInput)}
            >
              Add
            </Button>
          </Box>
          <Box px="2" minH="20vh" maxH="200px" overflow="scroll">
            {members.map(({ email, role }) => (
              <Box
                key={email}
                my="4"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Text textStyle="bodySmall" color="black">
                    {email}
                  </Text>
                  <Text
                    textStyle="bodySmall"
                    color="gray.medium"
                    textTransform="capitalize"
                  >
                    {role}
                  </Text>
                </Box>
                <Text
                  textStyle="bodySmall"
                  cursor="pointer"
                  onClick={() => removeEmail(email)}
                >
                  Remove
                </Text>
              </Box>
            ))}
          </Box>
          <Box>
            <Button
              colorScheme="primary"
              variant="fill"
              width="100%"
              onClick={sendInvites}
              borderRadius="7px"
              isDisabled={!members.length}
              isLoading={isSubmitting}
            >
              Send invites
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddMemberModal;
