import React from 'react';

import { Center, Tag, TagLabel } from '@chakra-ui/react';

import { MAX_SELECTED_AUDIENCES }  from 'src/api/constants';
import { AddAudienceAssetButton } from 'src/components/assets/addAsset';
import { AssetTypes, AudienceAssetDocument } from 'src/db';

import { AssetProps, DetailPage, RedirectToProps } from './DetailPage';
import { Path } from 'src/nav';

const redirectTo: RedirectToProps = {
  to: Path.font,
};

export function AudiencePage() {
  return <DetailPage<AudienceAssetDocument>
    title='Audiences'
    assetUiName='Audience'
    assetType={AssetTypes.audience}
    SelectedAsset={SelectedAudience}
    SuggestedAsset={SuggestedAudience}
    selectionLimit={MAX_SELECTED_AUDIENCES}
    selectionLimitErrorMessage={
      `Maximum of ${MAX_SELECTED_AUDIENCES} selected Audiences. Unselect one before selecting another`
    }
    AddAssetButton={AddAudienceAssetButton}
    redirectTo={redirectTo}
  />
}

function SelectedAudience({ asset }: AssetProps<AudienceAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{display: 'inline-flex'}}>{asset.value.targetAudience}</TagLabel>
    </Tag>
  </Center>);
}

function SuggestedAudience({ asset }: AssetProps<AudienceAssetDocument>) {
  // TODO: Use a large size/variant for Tag
  return (<Center>
    <Tag key={asset.id} size="lg">
      <TagLabel sx={{display: 'inline-flex'}}>{asset.value.targetAudience}</TagLabel>
    </Tag>
  </Center>);
}
