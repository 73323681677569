import { Box, useRadio } from "@chakra-ui/react"

const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as='label' w={props.width}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderRadius="8px"
        color="gray.mediumDark"
        _checked={{
          bg: 'primary.dark',
          color: 'white',
          fontWeight: 'bold',
        }}
        px={5}
        py={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.children}
      </Box>
    </Box>
  )
}

export default RadioCard