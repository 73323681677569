const colors = {

  system: {
    error: {
      dark: '#C62424',
      // TODO: Waiting on final system colors in new design
    },
    warn: {
      // TODO: Waiting on final system colors in new design
    },
    success: {
      // TODO: Waiting on final system colors in new design
    },
  },

  primary: {
    veryDark: '#095C64',
    dark: '#158E99',
    light: '#85C1C1',
    veryLight: '#E5F6F8',

    // Not in Figma. Only used for button hovers
    darkHover: '#127D87',
    darkActive: '#106F77',
    lightHover: 'rgba(133, 193, 193, 0.2)',
    lightActive: 'rgba(133, 193, 193, 0.4)',
  },

  secondary: {
    dark: '#FEA16D',
    light: '#FFE8B2',

    // Not in Figma, just including names for parody
    veryDark: '#FEA16D',
    veryLight: '#FFE8B2',

    // Not in Figma. Only used for button hovers
    darkHover: '#E08E60',
    darkActive: '#C67E55',
    lightHover: 'rgba(255, 232, 178, 0.2)',
    lightActive: 'rgba(255, 232, 178, 0.4)',
  },

  grayscale: {
    white: '#FFFFFF',
    offwhite: '#FFFDF8',
    light: '#F8F8F8',
    medium: '#D9D9D9',
    dark: '#797979',
    black: '#000000',
  },

  text: {
    body: '#095C64', // veryDark primary pallete
    placeholder: '#A4A4A4',
  },

  transparent: 'transparent',

  gray: {
    dark: '#374151',
    darkLightest: '#9CA3AF',
    medium: '#6B7280',
    mediumDark: '#4B5563',
    light: '#D1D5DB',
    lightest: '#F3F4F6',
  },

  error: '#C02346'
};
export default colors;
