export enum ApiMethods {
  // JSON METHODS
  ADD_ASSET = "/addAsset",
  ADD_COLOR_ASSET = '/addColorAsset',
  CLAIM_BUSINESS = "/claimYourBusiness",
  COMPLETE_BRAND_ACTION = "/completeBrandAction",
  CREATE_BRAND_ACTION_SOCIAL_MEDIA_POST = "/createBrandActionSocialMediaPost",
  CREATE_BUSINESS = "/createBusiness",
  CREATE_BUSINESS_FROM_URL = "/createBusinessFromUrl",
  CREATE_CHECKOUT_SESSION = "/createCheckoutSession",
  CREATE_PROFILE = "/createProfile",
  LOAD_AGENT_THREAD_MESSAGES = "/loadAgentThreadMessages",
  LOCK_BRANDKIT = "/lockBrandKit",
  UNLOCK_BRANDKIT = "/unlockBrandKit",
  GENERATE_ASSETS = "/generateAssets",
  GENERATE_REPORTS = "/generateReports",
  REVIEW_ASSET = "/reviewAsset",
  SELECT_ASSET = "/selectAsset",
  SEND_AGENT_THREAD_MESAAGE = "/sendAgentThreadMessage",
  UPDATE_ASSET_DATA = "/updateAssetData",
  UPDATE_BUSINESS_DESCRIPTION = "/updateBusinessDescription",
  UPDATE_BUSINESS_DIFFERENTIATOR = "/updateBusinessDifferentiator",
  UPDATE_BRAND_ACTION_SOCIAL_MEDIA_POST = "/updateBrandActionSocialMediaPostData",
  UPDATE_EDITED_LOGO = "/updateEditedLogo",
  UPDATE_PROFILE = "/updateProfile",
  UPDATE_TOS_AND_PP = "/updateTermsOfServiceAndPrivacyPolicy",
  UPDATE_REPORT = "/updateReport",
  DELETE_REPORT = "/deleteReport",
  SCRAPE_BUSINESS_URL = "/scrapeBusinessUrl",
  MEMBERS = "/members",
  USER_INVITATIONS = "/userInvitations",
  MEMBERS_DECLINE = "/members/decline",
  UPDATE_MEMBER_PERMISSIONS = "/memberPermissions",

  // FORM DATA METHODS
  ADD_LOGO_ASSET = "/addLogoAsset",
  BRAND_ASSET = "/brandAsset",
  ADD_FONT_ASSET = "/addFontAsset",
  ADD_SIMILAR_LOGO_ASSET = "/addSimilarLogoAsset",
  ADD_SOCIAL_MEDIA_POST_ASSET = "/addSocialMediaPostAsset",
}

export type ApiPending = Set<ApiMethods>;
