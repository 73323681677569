import {
  DocumentSnapshot,
  FirestoreDataConverter,
  SnapshotOptions,
} from "firebase/firestore";

type NotificationType = "USER_INVITATION" | "USER_LEAVE";

export interface NotificationDocument {
  id: string;
  businessId: string;
  createdAt: Date;
  modifiedAt: Date;
  messageContent: string;
  read: boolean;
  recipientId: string;
  senderId: string;
  type: NotificationType;
}

export const notificationConverter: FirestoreDataConverter<NotificationDocument> =
  {
    toFirestore(document: NotificationDocument) {
      return {
        id: document.id,
        businessId: document.businessId,
        createdAt: document.createdAt,
        modifiedAt: document.modifiedAt,
        messageContent: document.messageContent,
        read: document.read,
        recipientId: document.recipientId,
        senderId: document.senderId,
        type: document.type,
      };
    },

    fromFirestore(
      snapshot: DocumentSnapshot,
      options: SnapshotOptions
    ): NotificationDocument {
      const data = snapshot.data(options)!;
      return {
        id: snapshot.id,
        businessId: data.businessId,
        createdAt: data.createdAt.toDate(),
        modifiedAt: data.modifiedAt.toDate(),
        messageContent: data.messageContent,
        read: data.read,
        recipientId: data.recipientId,
        senderId: data.senderId,
        type: data.type,
      };
    },
  };
