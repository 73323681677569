import {
  Avatar,
  Box,
  Button,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdCheck, MdKeyboardArrowLeft } from "react-icons/md";
import { useApi } from "src/api";
import { ROLES } from "./AddMemberModal";

interface EditMemberPermissionsModalProps {
  isOpen: boolean;
  members: any[];
  handleClose: () => void;
  handleLeaveTeam: () => void;
}

const AssignNewOwnersModal = ({
  isOpen,
  members,
  handleClose,
  handleLeaveTeam
}: EditMemberPermissionsModalProps) => {
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();

  const [api, surfaceKnownErrors] = useApi();

  const handleSelectMember = (member: any) => {
    const exist = selectedMembers.some((m) => m.id === member.id);
    const newMembers = [...selectedMembers];
    if (!exist) {
      newMembers.push(member);
      setSelectedMembers(newMembers);
    } else {
      setSelectedMembers(newMembers.filter((m) => m.id !== member.id));
    }
  };

  const handleAssignNewOwners = async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    api
      .updateMemberPermissions(
        {
          junctionProfileId: selectedMembers.map((m => m.id)),
          role: ROLES.owner,
        },
        surfaceKnownErrors
      )
      .then(() => {
        handleClose();
        toast({
          description: "New owners successfully assigned",
          duration: 3000,
        });
        handleLeaveTeam();
      })
      .finally(() => {
        setIsSubmitting(false);
        setSelectedMembers([])
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="full">
      <ModalOverlay />
      <ModalContent
        mt="100px"
        borderRadius="16px 16px 0 0"
        maxH="75%"
        maxWidth={800}
      >
        <ModalHeader>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box position="absolute" left="4">
              <IconButton
                icon={
                  <Icon
                    as={MdKeyboardArrowLeft}
                    color={"gray.medium"}
                    boxSize="32px"
                  />
                }
                aria-label="Menu"
                onClick={handleClose}
                bg={"transparent"}
              />
            </Box>
            <Box>
              <Text color="gray.dark">Assign new owners</Text>
            </Box>
          </Box>
        </ModalHeader>
        <ModalBody>
          <Box my="8">
            {members.map((member) => {
              const { profile, role, id } = member;
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  padding={"16px 12px 16px 0"}
                  borderBottomWidth={0.5}
                  borderStyle="solid"
                  borderColor="gray.light"
                  cursor="pointer"
                  key={id}
                  onClick={() => handleSelectMember(member)}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" justifyContent="space-between" w="100%">
                      <Box display="flex" gap="2">
                        <Box>
                          <Avatar
                            name={profile?.profileInfo?.name}
                            src={
                              profile?.profileInfo?.profileImageInfo?.imageUrl
                            }
                            width="40px"
                            height="40px"
                          />
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                        >
                          <Text
                            color="gray.dark"
                            textStyle="body2"
                            fontWeight="bold"
                          >
                            {profile?.profileInfo?.name || "-"}
                          </Text>
                          <Text color="gray.medium" textStyle="body2">
                            {role}
                          </Text>
                        </Box>
                      </Box>
                      <Box h="40px">
                        {selectedMembers.some((member) => member.id === id) && (
                          <IconButton
                            icon={
                              <Icon
                                as={MdCheck}
                                color={"gray.mediumDark"}
                                boxSize="20px"
                              />
                            }
                            aria-label="selected member"
                            ml="2"
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box mt="2" mb="8">
            <Button
              colorScheme="primary"
              variant="fill"
              width="100%"
              onClick={handleAssignNewOwners}
              borderRadius="7px"
              isLoading={isSubmitting}
            >
              Next
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AssignNewOwnersModal;
