import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import {
  FirestoreContext, MembersContext, MembersContextProperties,
  unavailableCollectionContext
} from './contexts';
import { useBusinessId } from "./hooks";
import { profileBusinessConverter, ProfileBusinessDocument } from "./model/profileBusiness";

const junctionProfileBusiness = 'junctionProfileBusiness';

export function MembersProvider({ children }: PropsWithChildren) {
  const businessId = useBusinessId();

  return (
    !businessId ? (
      <MembersContext.Provider value={unavailableCollectionContext}>
      {children}
    </MembersContext.Provider>
    ) :
    <MembersProviderInner businessId={businessId}>
      {children}
    </MembersProviderInner>
  );
}

interface MembersProviderInnerProps {
  businessId: string;
}

export function MembersProviderInner({
  businessId, children
}: PropsWithChildren<MembersProviderInnerProps>) {
  const fs = useContext(FirestoreContext);
  const businessIdFilter = where('businessId', '==', businessId);

  const junctionRef = query<ProfileBusinessDocument>(
    collection(fs, junctionProfileBusiness).withConverter<ProfileBusinessDocument>(profileBusinessConverter),
    businessIdFilter,
  );

  const [junctions, junctionIsLoading] = useCollectionData(junctionRef);

  const [data, setData] = useState<any>([]);


  useEffect(() => {
    const fetchProfiles = async () => {
      if (junctions && junctions.length > 0) {
        const profileIds = junctions.map(junction => junction.profileId);
        if (profileIds.length) {
          const profilesRef = query(
            collection(fs, 'profiles'),
            where('profileId', 'in', profileIds)
          );

          const profileData = await getDocs(profilesRef);

          const combinedData = profileData.docs.map(doc => ({ ...junctions.find(j => j.profileId === doc.id), profile: {...doc.data()} }))
          
          setData(combinedData);
        }
      }
    };
  
    fetchProfiles();
  }, [junctions, fs]);

  const providedValue: MembersContextProperties = {
    collection: data || [],
    isLoading: junctionIsLoading
  }

  return (
    <MembersContext.Provider value={providedValue}>
      {children}
    </MembersContext.Provider>
  );
}