import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Button, Input, VStack, useToast, useDisclosure } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import ConfirmModal from '../ConfirmModal';
import NotesList from './NotesList';
import { useNotes } from './NotesContext';
import { Note } from './interfaces';

const NotesManager: React.FC = () => {
  const { notes, addNote, deleteNote, saveNote, updateNote } = useNotes();
  const [inputValue, setInputValue] = React.useState<string>('');
  const [noteToDelete, setNoteToDelete] = React.useState<number | null>(null);
  const [noteToSave, setNoteToSave] = React.useState<number | null>(null);
  const toast = useToast();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const { isOpen: isSaveConfirmOpen, onOpen: onSaveConfirmOpen, onClose: onSaveConfirmClose } = useDisclosure();

  const handleAddNote = () => {
    if (inputValue.trim() !== '') {
      const newNote: Note = { id: Date.now(), text: inputValue, isDraft: true };
      addNote(newNote);
      setInputValue('');
      toast({
        title: "Note Added",
        description: "Your note has been added as a draft.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSaveNote = (id: number) => {
    setNoteToSave(id);
    onSaveConfirmOpen();
  };

  const confirmSaveNote = () => {
    if (noteToSave !== null) {
      saveNote(noteToSave);
      toast({
        title: "Note Saved",
        description: "Your note has been saved.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    onSaveConfirmClose();
  };

  const handleDeleteNote = (id: number) => {
    setNoteToDelete(id);
    onConfirmOpen();
  };

  const confirmDeleteNote = () => {
    if (noteToDelete !== null) {
      deleteNote(noteToDelete);
      toast({
        title: "Note Deleted",
        description: "Your note has been deleted.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    onConfirmClose();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      handleAddNote();
    }
  };

  const handleEditNote = (id: number, newText: string) => {
    updateNote(id, newText);
  };

  return (
    <VStack spacing={4} p={4} borderWidth={1} borderRadius="md" bg="gray.100" width="100%" maxWidth="600px" mx="auto">
      <Input
        placeholder="Write a note..."
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        bg="white"
        borderColor="gray.300"
        focusBorderColor="teal.400"
        shadow="sm"
      />
      <Button
        onClick={handleAddNote}
        colorScheme="teal"
        isDisabled={inputValue.trim() === ''}
        leftIcon={<MdAdd />}
        width="100%"
      >
        Add Note
      </Button>
      <NotesList
        notes={notes}
        onSave={handleSaveNote}
        onDelete={handleDeleteNote}
        onEdit={handleEditNote}
      />
      <ConfirmModal
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        onConfirm={confirmDeleteNote}
        message="Are you sure you want to delete this note?"
      />
      <ConfirmModal
        isOpen={isSaveConfirmOpen}
        onClose={onSaveConfirmClose}
        onConfirm={confirmSaveNote}
        message="Are you sure you want to save this note?"
      />
    </VStack>
  );
};

export default NotesManager;
