import React from 'react';
import { Stack } from '@chakra-ui/react';
import NoteItem from './NoteItem';
import { NotesListProps } from './interfaces';

const NotesList: React.FC<NotesListProps> = ({ notes, onSave, onDelete, onEdit }) => {
  return (
    <Stack spacing={3} width="100%">
      {notes.map((note) => (
        <NoteItem
          key={note.id}
          note={note}
          onSave={onSave}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      ))}
    </Stack>
  );
};

export default NotesList;

