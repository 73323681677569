import {
  Avatar,
  Box,
  Button,
  SkeletonCircle,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { NotificationDocument } from "src/db/model/notification";
import relativeTime from "dayjs/plugin/relativeTime";
import { useContext, useEffect, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { FirestoreContext } from "src/db";

const USER_INVITATION_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  EXPIRED: "expired",
  DECLINED: "declined",
};

const NOTIFICATION_TYPE = {
  USER_LEAVE: "USER_LEAVE",
  USER_INVITATION: "USER_INVITATION",
};

dayjs.extend(relativeTime);

interface NotificationProps {
  notification: NotificationDocument;
  loadingDecline: boolean;
  handleDecline: (notification: NotificationDocument) => void;
  handleAccept: (notification: NotificationDocument) => void;
}

const Notification = ({
  notification,
  loadingDecline,
  handleDecline,
  handleAccept,
}: NotificationProps) => {
  const [userInvitation, setUserInvitation] = useState<any>(null);
  const [profile, setProfile] = useState<any>(null);
  const [selectedAssetName, setSelectedAssetName] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fs = useContext(FirestoreContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch User Invitation
        const userInvitationQuery = query(
          collection(fs, "userInvitations"),
          where("userId", "==", notification.recipientId),
          where("businessId", "==", notification.businessId),
          orderBy("createdAt", "desc")
        );
        const userInvitationSnapshot = await getDocs(userInvitationQuery);
        const invitations = userInvitationSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setUserInvitation(invitations[0]);

        // Fetch Profile
        const profileQuery = query(
          collection(fs, "profiles"),
          where("userId", "==", notification.senderId)
        );
        const profileSnapshot = await getDocs(profileQuery);
        const profiles = profileSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProfile(profiles[0]);

        // Fetch Asset Names
        const assetNamesQuery = query(
          collection(fs, "assetNames"),
          where("businessId", "==", notification.businessId),
          where("selectedForBrandKit", "==", true)
        );
        const assetNamesSnapshot = await getDocs(assetNamesQuery);
        const assetNames = assetNamesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSelectedAssetName(assetNames[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    notification.recipientId,
    notification.businessId,
    fs,
    notification.senderId,
  ]);

  if (loading) {
    return (
      <Box padding="6" boxShadow="lg" bg="white">
        <Box>
          <SkeletonCircle size="10" />
        </Box>
        <Box>
          <SkeletonText mt="4" noOfLines={2} spacing="4" />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" gap="16px" justifyContent="space-between" py="4">
      <Box display="flex" gap="16px" width="100%">
        <Avatar
          name={profile?.profileInfo?.name}
          src={profile?.profileInfo?.profileImageInfo?.imageUrl}
          width="44px"
          height="44px"
        />
        <Box
          borderBottomWidth="1.5px"
          borderColor="gray.light"
          borderStyle="solid"
          pb="4"
          w="100%"
        >
          <Box display="flex">
            <Text maxW="220px">
              <b>{profile?.profileInfo?.name || profile?.profileInfo?.email}</b>{" "}
              {notification.messageContent}{" "}
              {selectedAssetName?.assetData?.assetValue?.name || "Brand name"}
            </Text>
          </Box>
          <Box mt="2" mb="3">
            <Text
              color="gray.darkLightest"
              textStyle="body2"
              textTransform="capitalize"
            >
              {dayjs(notification.createdAt).fromNow()}
            </Text>
          </Box>
          {notification?.type === NOTIFICATION_TYPE.USER_INVITATION && (
            <Box>
              {userInvitation?.status === USER_INVITATION_STATUS.PENDING && (
                <>
                  <Button
                    variant="outline"
                    borderRadius="7px"
                    mr="3"
                    height="32px"
                    onClick={() => handleDecline(notification)}
                    isLoading={loadingDecline}
                  >
                    Decline
                  </Button>
                  <Button
                    variant="fill"
                    borderRadius="7px"
                    height="32px"
                    onClick={() => handleAccept(notification)}
                  >
                    Accept
                  </Button>
                </>
              )}
              {userInvitation?.status === USER_INVITATION_STATUS.DECLINED && (
                <Button
                  variant="fill"
                  borderRadius="7px"
                  height="32px"
                  color="error"
                  bg="#C023461A"
                >
                  Declined
                </Button>
              )}
              {userInvitation?.status === USER_INVITATION_STATUS.ACCEPTED && (
                <Button
                  variant="fill"
                  borderRadius="7px"
                  height="32px"
                  color="primary.dark"
                  bg="#158E991A"
                >
                  Accepted
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        {!notification.read && (
          <Box borderRadius="50%" bg="error" width="8px" height="8px" mt="3" />
        )}
      </Box>
    </Box>
  );
};

export default Notification;
